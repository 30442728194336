<template>
  <v-dialog
    v-model="dialog"
    persistent
    fullscreen
    hide-overlay
    :transition="transition"
  >
    <v-card>
      <v-container fill-height fluid>
        <v-row align="center" justify="center">
          <v-card max-width="300" class="mb-16" flat>
            <div class="text-center" justify="center">
              <v-slide-y-reverse-transition>
                <v-chip class="offset" v-if="clippyText">
                  {{ clippyText }}
                </v-chip>
              </v-slide-y-reverse-transition>
              <v-img
                class="ml-16"
                max-width="200"
                :src="require('../assets/clippy.png')"
              >
              </v-img>
            </div>
            <v-slide-y-transition>
              <v-btn
                v-if="showLogin"
                color="indigo"
                class="mt-8 ml-8"
                :href="loginURL"
              >
                Login with Discord
              </v-btn>
            </v-slide-y-transition>
          </v-card>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.offset {
  position: absolute;
  right: 170px;
  top: -20px;
}
</style>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  name: "Login",

  data: () => ({
    dialog: true,
    transition: false,
    clippyText: "",
    showLogin: false,
  }),
  computed: {
    ...mapState(["isAuthenticated"]),
    ...mapState("user", ["guilds"]),
    loginURL() {
      return process.env.VUE_APP_API_BASEURL + "/login";
    },
  },
  methods: {
    ...mapMutations(["setAuthenticated", "setActiveGuild"]),
    ...mapActions("user", {
      poke: "poke",
      getUser: "getUser",
      getGuilds: "getGuilds",
    }),
    ...mapActions("clips", {
      getClips: "getClips",
    }),
  },
  created() {
    setTimeout(() => {
      this.poke()
        .then((response) => {
          if (response.status === 200) {
            this.clippyText = "Welcome back!";

            this.getUser().then(() => {
              this.getGuilds().then(() => {
                let lastGuild =
                  JSON.parse(localStorage.getItem("activeGuild")) ||
                  this.guilds[0];
                this.setActiveGuild(lastGuild);
                this.getClips();
                this.setAuthenticated();
              });
            });

            setTimeout(() => {
              this.dialog = false;
            }, 1000);
          } else {
            this.clippyText = "Hi, I'm Clippy";
            this.showLogin = true;
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.clippyText = "Oops, you don't seem to be authorized...";
            this.showLogin = true;
          } else {
            this.clippyText = "This is awkward, I think I'm broken...";
            setTimeout(() => {
              this.clippyText = "Try refreshing the page later.";
            }, 4000);
          }
        })
        .then(() => {});
    }, 200);
  },
};
</script>
