<template>
  <v-container fluid class="scrollable" id="log-container">
    <div>
      <v-avatar color="grey darken-2" @click="reload()">
        <v-icon size="36"> mdi-pound </v-icon>
      </v-avatar>
      <h2>Welcome to #{{ channelName }}!</h2>
      <p class="text-caption grey--text">
        This is the start of the #{{ channelName }} channel
      </p>
    </div>

    <v-divider class="chat-divider mb-3"></v-divider>
    <v-btn @click="getLogs()" :loading="loading">Load More</v-btn>

    <messages keyName="log" :items="messages" />
  </v-container>
</template>

<script>
import Messages from "../components/shared/Messages.vue";
import moment from "moment-timezone";

import { mapState, mapActions } from "vuex";
export default {
  name: "Log",
  components: {
    Messages,
  },
  data: () => ({
    messages: [],
    timestamp: moment().utc(),
    loading: false,
  }),
  computed: {
    ...mapState(["socket"]),
    channelName() {
      return (this.$route.params.clip || this.$route.name)
        .toLowerCase()
        .split(" ")
        .join("-");
    },
    height() {
      return this.clips.length * 20;
    },
  },
  methods: {
    ...mapActions("clips", {
      getLog: "getLog",
    }),
    getLogs(scroll) {
      if (this.loading) return;
      this.loading = true;
      this.getLog(this.timestamp).then((response) => {
        let logs = response.data;
        this.timestamp = logs[logs.length - 1].timestamp;
        logs.forEach((l) => {
          this.messages.unshift({
            avatar: require("../assets/clippy.png"),
            username: "Clippy",
            nameColor: "primary",
            bot: true,
            time: this.convertDate(l.timestamp)._d.toLocaleString(),
            text: [`${l.username} played ${l.clipName}`],
            delay: 500,
          });
        });
        if (scroll) {
          setTimeout(() => {
            document.getElementById("log-container").scrollTop =
              document.getElementById("messages").scrollHeight;
          }, 1);
        }
        this.loading = false;
      });
    },
    convertDate(date) {
      return moment.utc(date);
    },
    init() {
      this.messages.length = 0;
      this.timestamp = moment().utc();
      this.getLogs(true);
    },
    reload() {
      this.init();
    },
    playing(data) {
      this.messages.push({
        avatar: require("../assets/clippy.png"),
        username: "Clippy",
        nameColor: "primary",
        bot: true,
        time: moment()._d.toLocaleString(),
        text: [`${data.user} played ${data.name}`],
      });
      if (
        document.getElementById("log-container").scrollTop + 1300 >=
        document.getElementById("messages").scrollHeight
      )
        setTimeout(() => {
          document.getElementById("log-container").scrollTop =
            document.getElementById("messages").scrollHeight;
        }, 1);
    }
  },
  created() {
    this.init();

    this.socket.on("playing", this.playing);
  },
  destroyed() {
    this.socket.off("playing", this.playing);
  },
};
</script>
