<template>
  <v-card
    class="my-0 flex d-flex flex-column"
    @click="play()"
    :ripple="false"
    :disabled="chain.playing"
    ref="chain-container"
  >
    <div class="d-flex flex-no-wrap justify-space-between">
      <v-card-title
        class="text-h6 py-1 text-truncate"
        v-text="chain.name"
      ></v-card-title>

      <div class="mr-2 d-flex justify-end">
        <!-- <v-btn
          v-if="editable != true || !chain.modify"
          class="my-2"
          icon
          plain
          @click.stop="showInfo = true"
          :ripple="false"
        >
          <v-icon>mdi-information-outline</v-icon>
        </v-btn> -->
        <v-menu left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="my-2"
              v-bind="attrs"
              v-on="on"
              icon
              plain
              :ripple="false"
            >
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <!-- <v-list-item @click="showInfo = true">
              <v-list-item-title
                class="grey--text text--lighten-1 text-subtitle-2"
              >
                Info
              </v-list-item-title>
              <v-list-item-action>
                <v-icon color="grey">mdi-information-outline</v-icon>
              </v-list-item-action>
            </v-list-item> -->
            <v-list-item @click="showEdit()">
              <v-list-item-title
                class="grey--text text--lighten-1 text-subtitle-2"
              >
                Edit
              </v-list-item-title>
              <v-list-item-action>
                <v-icon color="grey">mdi-pencil</v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-list-item @click="remove()">
              <v-list-item-title
                class="red--text text--lighten-1 text-subtitle-2"
              >
                Delete
              </v-list-item-title>
              <v-list-item-action>
                <v-icon :color="chain.favorite ? 'red' : ''"
                  >mdi-trash-can</v-icon
                >
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>

    <div class="flex d-flex flex-column">
      <div v-show="showDescriptions">
        <v-divider class="mx-4"></v-divider>

        <v-card-text
          class="
            pt-2
            pb-2
            grey--text
            text--lighten-1
            flex
            d-flex
            flex-column
            justify-space-between
          "
        >
          <!-- <div class="py-2 text-subtitle-1">
          <v-icon color="grey" dense>mdi-account-voice</v-icon>
          {{ chain.voices.join(" + ") }}
        </div> -->

          <div v-for="(clip, index) in chain.clips" :key="index">
            {{ index + 1 }}. {{ clip.name }}
          </div>
        </v-card-text>
      </div>

      <v-overlay absolute v-if="chain.playing">
        <lottie-animation
          style="height: 150px"
          :animationData="require('assets/speakers.json')"
          :loop="true"
        />
      </v-overlay>
      <v-overlay absolute v-if="chain.error">
        <lottie-animation
          style="height: 150px"
          :animationData="require('assets/error.json')"
          :loop="true"
        />
      </v-overlay>
    </div>
  </v-card>
</template>

<style>
.chain-overlay .v-overlay__content {
  height: 100%;
  width: 100%;
}
</style>

<script>
import { mapState, mapActions } from "vuex";
import LottieAnimation from "lottie-web-vue";
import moment from "moment-timezone";

export default {
  name: "Chain",
  components: {
    LottieAnimation,
  },
  props: ["chain", "editable", "bus"],
  data: () => ({
    showMenu: false,
    x: 0,
    y: 0,
  }),
  computed: {
    ...mapState("clips", ["showDescriptions"]),
  },
  methods: {
    ...mapActions("clips", {
      playChain: "playChain",
      deleteChain: "deleteChain",
    }),
    showEdit() {
      this.bus.$emit("showEdit", this.chain);
    },
    remove() {
      this.deleteChain(this.chain.chainID)
        .then(() => {})
        .catch((err) => {
          console.error(err);
        });
    },
    play() {
      if (this.chain.playing && !this.chain.error) {
        return;
      }
      this.chain.playing = true;
      this.playChain(this.chain.chainID)
        .then(() => {
          this.chain.playing = false;
        })
        .catch((err) => {
          this.chain.error = true;
          console.log(err);
        })
        .then(() => {
          this.chain.playing = false;
          setTimeout(() => {
            this.chain.error = false;
          }, 2000);
        });
    },
    show(e) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    convertDate(date) {
      return moment.utc(date)._d.toLocaleString();
    },
  },
  created() {},
};
</script>
