<template>
  <v-container fluid class="scrollable">
    <div>
      <v-avatar color="grey darken-2" @click="init()">
        <v-icon size="36"> mdi-pound </v-icon>
      </v-avatar>
      <h2>Welcome to #{{ channelName }}!</h2>
      <p class="text-caption grey--text">
        This is the start of the #{{ channelName }} channel
      </p>
    </div>

    <v-divider class="chat-divider mb-3"></v-divider>

    <messages keyName="stats" :items="messages" />

    <apexchart
      style="
        border-left: red 4px solid;
        border-radius: 4px;
        background-color: rgb(66, 66, 66);
      "
      v-if="clips.length"
      type="bar"
      :height="height"
      :options="chartOptions"
      :series="series"
      class="mr-4"
      ref="realtimeChart"
    ></apexchart>
    <div class="mt-2">
      <v-btn
        :disabled="clipRangeStart <= 0"
        @click="getClipRange(clipRangeStart - 20, clipRangeEnd - 20)"
        class="mr-2"
        >Previous</v-btn
      >
      <v-btn
        :disabled="clipRangeEnd >= clips.length"
        @click="getClipRange(clipRangeStart + 20, clipRangeEnd + 20)"
        >Next</v-btn
      >
    </div>
  </v-container>
</template>

<script>
import Messages from "../components/shared/Messages.vue";
import { mapState, mapActions } from "vuex";
export default {
  name: "Stats2",
  components: {
    Messages,
  },
  data: () => ({
    messages: [],
    clips: [],
    clipRange: [],
    clipRangeStart: 0,
    clipRangeEnd: 20,
    users: [],
    series: [],
    chartOptions: {
      theme: {
        mode: "dark",
        palette: "palette1",
      },
      colors: [
        "#F44336",
        "#673AB7",
        "#03A9F4",
        "#4CAF50",
        "#FFEB3B",
        "#FF5722",
        "#E91E63",
        "#3F51B5",
        "#00BCD4",
        "#8BC34A",
        "#FFC107",
        "#9C27B0",
        "#2196F3",
        "#009688",
        "#CDDC39",
        "#FF9800",
      ],
      chart: {
        type: "bar",
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        position: "top",
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      xaxis: {
        categories: [],
      },
    },
  }),
  computed: {
    ...mapState("user", ["user"]),
    channelName() {
      return (this.$route.params.clip || this.$route.name)
        .toLowerCase()
        .split(" ")
        .join("-");
    },
    height() {
      return this.clipRange.length * 20;
    },
  },
  methods: {
    ...mapActions("clips", {
      getStats: "getStats2",
    }),
    init() {
      this.messages.splice(0);
      this.messages.push({
        avatar: this.user.avatarURL,
        username: this.user.display_name || this.user.global_name,
        time: new Date().toLocaleString(),
        text: ["!stats"],
      });
      this.getStats().then((response) => {
        this.messages.push({
          avatar: require("../assets/clippy.png"),
          username: "Clippy",
          nameColor: "primary",
          bot: true,
          time: new Date().toLocaleString(),
          text: ["Here you go:"],
          delay: 500,
        });
        var data = response.data;
        var clips = [];
        var users = [];

        data.forEach((c) => {
          var clip = clips.find((f) => f.clip == c.clip);
          if (clip) {
            clip.data.push({ username: c.displayName || c.username, count: c.count });
            clip.total += c.count;
          } else {
            clips.push({
              clip: c.clip,
              name: c.name,
              data: [
                {
                  username: c.displayName || c.username,
                  count: c.count,
                },
              ],
              total: c.count,
            });
          }

          var user = users.find((f) => f.name == (c.displayName || c.username));
          if (user) {
            console.log();
          } else {
            users.push({
              name: c.displayName || c.username,
              data: [],
            });
          }
        });

        clips.sort((a, b) => {
          return b.total - a.total;
        });

        this.clips = clips;
        this.users = users;

        this.getClipRange(this.clipRangeStart, this.clipRangeEnd);

        // this.clipRange = this.clips.slice(0, 20);

        // this.clipRange.forEach((clip) => {
        //   users.forEach((user) => {
        //     var match = clip.data.find((x) => x.username == user.name);
        //     if (match) {
        //       user.data.push(match.count);
        //     } else {
        //       user.data.push(0);
        //     }
        //   });
        // });
        // this.users = users;

        // this.chartOptions.xaxis.categories = [];

        // this.clipRange.forEach((c) => {
        //   this.chartOptions.xaxis.categories.push(c.name);
        // });

        // this.series = this.users.filter((u) => u.data.some((x) => x > 0));

        // this.series = [
        //   { name: "Plays", data: plays },
        //   { name: "Users", data: users },
        // ];
      });
    },
    getClipRange(start, end) {
      this.clipRangeStart = start;
      this.clipRangeEnd = end;
      this.clipRange = this.clips.slice(start, end);

      this.users.forEach((user) => {
        user.data = [];
      });

      this.clipRange.forEach((clip) => {
        this.users.forEach((user) => {
          var match = clip.data.find((x) => (x.displayName || x.username) == user.name);
          if (match) {
            user.data.push(match.count);
          } else {
            user.data.push(0);
          }
        });
      });

      this.chartOptions = {
        ...this.chartOptions,
        ...{
          xaxis: {
            categories: [],
          },
        },
      };

      this.clipRange.forEach((c) => {
        this.chartOptions.xaxis.categories.push(c.name);
      });

      this.series = this.users.filter((u) => u.data.some((x) => x > 0));
    },
  },
  created() {
    this.init();
  },
};
</script>
