<template>
  <v-menu
    :close-on-content-click="false"
    rounded="sm"
    bottom
    left
    nudge-left="10"
    nudge-bottom="50"
    max-width="400"
    max-height="100%"
  >
    <template v-slot:activator="{ on: menu, attrs }">
      <v-tooltip bottom color="black">
        <template v-slot:activator="{ on: button }">
          <v-btn
            v-show="!show"
            v-on="{ ...button, ...menu }"
            v-bind="attrs"
            icon
            plain
            color="white"
            :ripple="false"
          >
            <v-icon>mdi-heart</v-icon>
          </v-btn>
        </template>
        <span>Favorites</span>
      </v-tooltip>
    </template>

    <v-card class="overflow-hidden">
      <v-app-bar absolute color="#202225" flat>
        <v-toolbar-title>Favorites</v-toolbar-title>
      </v-app-bar>
      <v-sheet class="overflow-y-auto" :style="`padding-top: 54px;`">
        <v-container
          class="discord-dark-4 scrollable thin-scrollbar"
          :style="`${clips.length == 0 ? 'max-height: 200px' : ''}`"
        >
          <v-row class="pt-3 pb-4">
            <v-col v-if="clips.length == 0">
              <lottie-animation
                style="height: 120px"
                :animationData="require('assets/heart.json')"
                :loop="true"
              />
              <p class="text-center grey--text mb-0">
                This channel doesn't have any favorites... yet.
              </p>
            </v-col>
            <v-col
              v-for="clip in clips"
              :key="clip.clip"
              cols="12"
              class="d-flex flex-column mt-2 py-0"
            >
              <clip :clip="clip" />
            </v-col>
          </v-row>
        </v-container>
        <v-toolbar flat color="#202225" v-if="clips.length == 0">
          <v-toolbar-title class="flex d-flex flex-column">
            <span
              class="
                text-subtitle-2
                green--text
                text--darken-1
                d-flex
                align-self-center
              "
            >
              PROTIP
            </span>
            <span class="text-caption grey--text d-flex align-self-center">
              Users can favorite with the heart icon
            </span>
          </v-toolbar-title>
        </v-toolbar>
      </v-sheet>
    </v-card>
  </v-menu>
</template>


<script>
import { mapState, mapActions, mapGetters } from "vuex";

import Clip from "components/shared/Clip.vue";
import LottieAnimation from "lottie-web-vue";

// import utils from "../util/utils"

export default {
  name: "Favorite",
  components: {
    Clip,
    LottieAnimation,
  },
  props: ["show"],
  data: () => ({
    actionTimeoutID: null,
  }),
  computed: {
    ...mapState(["isAuthenticated", "activeGuild"]),
    ...mapState({
      user: (s) => s.user.user,
    }),
    ...mapGetters("clips", {
      favoriteClips: "favoriteClips",
      favoriteClipVoices: "favoriteClipVoices",
    }),
    clips() {
      if (this.$route.params.voice && this.favoriteClipVoices.length) {
        return (
          this.favoriteClipVoices.find(
            (v) =>
              v.voice.toLowerCase() == this.$route.params.voice.toLowerCase()
          ) || { clips: [] }
        ).clips;
      } else {
        return this.favoriteClips;
      }
    },
  },
  methods: {
    ...mapActions("user", {
      getUser: "getUser",
    }),
  },
  created() {},
};
</script>