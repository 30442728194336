<template>
  <v-container fluid class="scrollable" @dragenter.prevent="onDragEnter()">
    <!-- <v-alert
      dense
      rounded="lg"
      color="#5865F2"
      class="new-clips pl-3 pr-1 pb-0 pt-3"
      v-show="showNewClips"
    >
      <v-row align="center">
        <v-col class="grow"> New clips have been added. </v-col>
        <v-col class="shrink">
          <v-btn x-small text @click="loadNewClips()">Load new clips</v-btn>
        </v-col>
      </v-row>
    </v-alert> -->
    <div>
      <v-avatar color="grey darken-2" @click="init()">
        <v-icon size="36"> mdi-pound </v-icon>
      </v-avatar>
      <h2>Welcome to #{{ channelName }}!</h2>
      <p class="text-caption grey--text">
        This is the start of the #{{ channelName }} channel
      </p>
    </div>

    <v-divider class="chat-divider mb-3"></v-divider>

    <messages keyName="chains" :existing="messages" />

    <v-switch v-model="toggleDescriptions" label="Show Descriptions" class="mt-0"></v-switch>

    <v-row class="px-1">
      <v-col
        v-for="chain in filteredChains"
        :key="chain.chainID"
        xl="3"
        lg="4"
        md="4"
        sm="6"
        cols="12"
        class="d-flex flex-column px-1 py-1"
      >
        <chain :chain="chain" :editable="true" :bus="bus" />
      </v-col>
    </v-row>

    <v-footer app height="72" inset class="discord-dark-2">
      <v-text-field
        background-color="discord-dark-1"
        dense
        flat
        class="rounded-lg white-hint dark-placeholder"
        solo
        prepend-inner-icon="mdi-plus-circle"
        @click:prepend-inner="showEdit"
        @focus="showEdit"
        :placeholder="`Message #${channelName}`"
        hint="●●● Clippy is typing..."
        :persistent-hint="hint"
        ref="messagebar"
      ></v-text-field>
    </v-footer>
    <edit-chain :bus="bus" />
  </v-container>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import Messages from "components/shared/Messages.vue";
import Chain from "components/shared/Chain.vue";
import EditChain from "components/shared/EditChain.vue";

// import LottieAnimation from "lottie-web-vue";

export default {
  name: "Chains",
  components: {
    Messages,
    // LottieAnimation,
    Chain,
    EditChain,
  },
  data: () => ({
    bus: new Vue(),
    messages: [],
    hint: true,
    toggleDescriptions: null,
  }),
  computed: {
    ...mapState(["searchText", "activeGuild", "socket"]),
    ...mapGetters(["isActive"]),
    ...mapState("user", ["user"]),
    ...mapState("clips", ["showDescriptions"]),
    ...mapGetters("clips", {
    //   clipsCount: "clipsCount",
      filteredChains: "filteredChains",
    }),
    date() {
      return new Date().toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    channelName() {
      return (this.$route.params.voice || this.$route.name)
        .toLowerCase()
        .split(" ")
        .join("-");
    },
    foundChainsMessage() {
      return `I've found ${this.clipsCount} chain${
        this.clipsCount == 1 ? "" : "s"
      } for you!`;
    },
    clipsCount() {
        return this.filteredChains.length;
    }
  },
  watch: {
    clipsCount() {
      Vue.set(this.messages[0], "text", [
        `!${this.$route.name.toLowerCase()} ${this.$route.params.voice || ""} ${
          this.searchText
        }`,
      ]);
      if (this.messages.length > 1)
        Vue.set(this.messages[1], "text", [this.foundChainsMessage]);
    },
    $route() {
      Vue.set(this.messages[0], "text", [
        `!${this.$route.name.toLowerCase()} ${this.$route.params.voice || ""} ${
          this.searchText
        }`,
      ]);
      Vue.set(this.messages[1], "text", [this.foundChainsMessage]);
    },
    searchText(val) {
      Vue.set(this.messages[0], "edited", true);
      Vue.set(this.messages[0], "text", [
        `!${this.$route.name.toLowerCase()} ${
          this.$route.params.voice || ""
        } ${val}`,
      ]);
      Vue.set(this.messages[1], "edited", true);
      Vue.set(this.messages[1], "text", [this.foundChainsMessage]);
    },
    toggleDescriptions(val) {
      this.setShowDescriptions(val);
    },
  },
  methods: {
    ...mapMutations(["enableSearch", "disableSearch"]),
    ...mapMutations("clips", ["setShowDescriptions"]),
    ...mapActions("clips", {
      getChains: "getChains",
      playChain: "playChain",
    }),
    play() {
      this.playChain(1);
    },
    showEdit() {
      this.$refs.messagebar.blur();
      setTimeout(() => {
        this.bus.$emit("showEdit", {});
      }, 1);
    },
    init() {
      this.messages.splice(0);
      this.messages.push({
        avatar: this.user.avatarURL,
        username: this.user.display_name || this.user.global_name,
        time: new Date().toLocaleString(),
        text: [
          `!${this.$route.name.toLowerCase()} ${
            this.$route.params.voice || ""
          }`,
        ],
      });
      this.getChains().then(() => {
        this.messages.push({
          avatar: require("assets/clippy.png"),
          username: "Clippy",
          nameColor: "primary",
          bot: true,
          time: new Date().toLocaleString(),
          text: [this.foundChainsMessage],
        });
        this.hint = false;
        // this.enableSearch();
      });
    },
  },
  created() {
    this.init();

    this.toggleDescriptions = this.showDescriptions;
  },
  destroyed() {
    // this.disableSearch();
  },
};
</script>
