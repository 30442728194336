<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
      overlay-opacity=".7"
    >
      <v-card
        class="discord-dark-2"
        @drop.prevent="onDrop($event)"
        @dragenter.prevent="dragover = true"
      >
        <v-card-title>
          <span class="text-h5">Upload Clip</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" :disabled="uploading">
              <v-row>
                <v-col cols="12">
                  <v-file-input
                    v-model="file"
                    :accept="['audio/mpeg', 'audio/ogg']"
                    label="Audio File"
                    show-size
                    outlined
                    :rules="[rules.required, rules.fileSize]"
                    :class="dragover ? 'highlight-drag' : ''"
                  ></v-file-input>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="name"
                    label="Name"
                    outlined
                    :rules="[rules.required, rules.nameSize]"
                    counter="20"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="desc"
                    label="Description/Transcript"
                    required
                    outlined
                    :rules="[rules.required, rules.descSize]"
                    counter="50"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-combobox
                    v-model="voices"
                    :items="existingVoices"
                    :search-input.sync="voiceSearch"
                    hide-selected
                    label="Add some sources"
                    hint="Who's featured in this wonderful clip? Ex. Dan, Jimmy, The Lich King, Some hobo, etc."
                    multiple
                    persistent-hint
                    outlined
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            No results matching "<strong>{{
                              voiceSearch
                            }}</strong
                            >". Press <kbd>enter</kbd> to create a new one
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <template
                      v-slot:selection="{ attrs, item, parent, selected }"
                    >
                      <v-chip v-bind="attrs" :input-value="selected" small>
                        <span class="pr-2">
                          {{ item }}
                        </span>
                        <v-icon small @click="parent.selectItem(item)">
                          $delete
                        </v-icon>
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="discord-dark-3">
          <div class="red--text">{{ message }}</div>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false"> Cancel </v-btn>
          <v-btn depressed color="primary" @click="submit" :loading="uploading">
            Upload
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dragover" fullscreen :transition="false">
      <v-card
        flat
        color="rgba(33,33,33,.8)"
        @drop.prevent.stop="onDrop($event)"
        @dragenter.prevent.stop="onDragEnter()"
        @dragover.prevent.stop="onDragEnter()"
        @dragleave.prevent.stop="onDragLeave()"
      >
        <v-container fill-height fluid>
          <v-row align="center" justify="center">
            <v-col class="text-center">
              <lottie-animation
                style="height: 300px"
                :animationData="require('assets/upload.json')"
                :loop="true"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<style scoped>
.highlight-drag {
  color: #4caf50 !important;
  caret-color: #4caf50 !important;
}
</style>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import LottieAnimation from "lottie-web-vue";

export default {
  name: "Upload",
  props: ["bus"],
  components: {
    LottieAnimation,
  },
  data: () => ({
    dialog: false,

    file: null,
    name: "",
    desc: "",
    voices: [],
    voiceSearch: null,

    message: "",
    uploading: false,
    dragover: false,

    rules: {
      required: (value) => !!value || "Required.",
      fileSize: (value) => (value && value.size <= 500 * 1024) || "Max 500kb",
      nameSize: (value) => (value && value.length <= 20) || "Max Length 20",
      descSize: (value) => (value && value.length <= 50) || "Max Length 50",
    },
  }),
  computed: {
    ...mapState(["activeGuild"]),
    ...mapGetters("clips", {
      existingVoices: "voices",
    }),
  },
  methods: {
    ...mapActions("clips", {
      uploadClip: "uploadClip",
    }),
    onDrop(e) {
      this.dragover = false;
      var files = e.target.files || e.dataTransfer.files;
      if (files.length) {
        this.dialog = true;
        this.file = files[0];
      }
    },
    onDragEnter() {
      this.dragover = true;
    },
    onDragLeave() {
      this.dragover = false;
    },
    async submit() {
      var val = this.$refs.form.validate();
      if (val) {
        // console.log(this.name.replace(/[\W_]+/g, " ").replace(/ /g, "_"));

        const formData = new FormData();
        formData.append("guildID", this.activeGuild.id);
        formData.append("file", this.file);
        formData.append("name", this.name);
        formData.append("desc", this.desc);
        formData.append("voices", JSON.stringify(this.voices));
        this.uploading = true;
        this.uploadClip(formData)
          .then(() => {
            this.dialog = false;
          })
          .catch((err) => {
            console.error(err);
            this.message = "Ruh oh something went wrong...";
          })
          .then(() => (this.uploading = false));
      }
    },
  },
  created() {
    this.bus.$on("showUpload", () => {
      this.dialog = true;
    });

    this.bus.$on("showDragover", () => {
      this.dragover = true;
    });
  },
};
</script>
