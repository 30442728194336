import Vue from "vue";

// initial state
const state = {
  user: null,
  guilds: [],
};

let getAvatarURL = (state) => {
  return `https://cdn.discordapp.com/avatars/${state.user.id}/${state.user.avatar}.png?size=256`;
};
// getters
// const getters = {
//   actionableGuilds: (state) => {
//     return state.guilds.filter((g) => g.owner);
//   },
//   ownedGuilds: (state) => {
//     return state.guilds.filter((g) => g.owner);
//   },
//   adminGuilds: (state) => {
//     return state.guilds.filter((g) => g.owner || g.permissions & (1 << 3));
//   },
//   avatarURL: (state) => {
//     return getAvatarURL(state);
//   },
// };

let isOwner = (guild) => {
  return guild.owner;
};

let isAdmin = (guild) => {
  return isOwner(guild) || guild.permissions & (1 << 3);
};

let isClippyActive = (guild) => {
  return isOwner(guild) || isAdmin(guild) || guild.clippy;
};
// getters
const getters = {
  actionableGuilds: (state) => {
    return state.guilds.filter(isClippyActive);
  },
  ownedGuilds: (state) => {
    return state.guilds.filter(isOwner);
  },
  adminGuilds: (state) => {
    return state.guilds.filter(isAdmin);
  },
  avatarURL: (state) => {
    return getAvatarURL(state);
  },
};

// actions
const actions = {
  poke() {
    return new Promise((resolve, reject) => {
      Vue.axios
        .get("/poke", {
          timeout: 5000,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getGuilds({ commit }) {
    return new Promise((resolve) => {
      Vue.axios.get("/guilds").then((response) => {
        // let guilds = response.data;
        commit(
          "setGuilds",
          response.data.sort((a) => (a.id === "136264752233054208" || a.id === "857743441739644928" ? -1 : 1))
        );
        resolve();
      });
    });
  },
  getUser({ commit }) {
    return new Promise((resolve) => {
      Vue.axios.get("/me").then((response) => {
        commit("setUser", response.data);
        resolve();
      });
    });
  },
};

// mutations
const mutations = {
  setGuilds(state, guilds) {
    state.guilds = guilds;
  },
  setUser(state, user) {
    state.user = user;
    state.user.avatarURL = getAvatarURL(state);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
