<template>
  <v-list-item class="pl-14">
    <v-list-item-content class="py-0">
      <v-card class="discord-dark-3" max-width="400">
        <v-card-title
          class="
            text-uppercase text-subtitle-1
            grey--text
            text--lighten-1
            font-weight-bold
            pb-0
            wrap-text
          "
        >
          You've been invited to join a server
        </v-card-title>
        <v-card-actions class="pt-0">
          <v-list-item class="grow">
            <v-list-item-avatar color="grey darken-3" rounded="lg">
              C
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-subtitle class="white--text font-weight-medium"
                >Clippy Support</v-list-item-subtitle
              >
              <div class="grey--text d-flex text-caption font-weight-medium">
                <v-icon x-small color="green" class="align-self-center mr-1"
                  >mdi-checkbox-blank-circle</v-icon
                >
                <span>{{ online }} Online</span>
                <v-icon x-small color="grey" class="align-self-center ml-2 mr-1"
                  >mdi-checkbox-blank-circle</v-icon
                >
                <span>{{ members }} Members</span>
              </div>
            </v-list-item-content>

            <v-row align="center" justify="end" class="shrink">
              <v-btn color="green" href="https://discord.gg/ENFR3RzZQ7"
                >Join</v-btn
              >
            </v-row>
          </v-list-item>
        </v-card-actions>
      </v-card>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { randomNumber } from "@utils/utils.js";

export default {
  name: "ServerInvite",
  data: () => ({}),
  computed: {
    online: () => randomNumber(1, 20),
    members: () => randomNumber(20, 50),
  },
  methods: {},
};
</script>
