import Vue from "vue";
import Vuex from "vuex";

import user from "./modules/user";
import users from "./modules/users";
import clips from "./modules/clips";

import { io } from "socket.io-client";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isAuthenticated: false,
    searchEnabled: false,
    searchText: "",
    activeGuild: {
      id: "",
      name: "",
    },
    socket: io(process.env.VUE_APP_API_BASEURL, {
      withCredentials: true
    })
  },
  getters: {
    isActive: (state) => {
      return !!user.state.user && !!state.isAuthenticated;
    },
  },
  mutations: {
    setAuthenticated(state) {
      state.isAuthenticated = true;
    },
    enableSearch(state) {
      state.searchEnabled = true;
    },
    disableSearch(state) {
      state.searchEnabled = false;
      state.searchText = "";
    },
    setSearchText(state, searchText) {
      state.searchText = searchText || "";
    },
    clearSearchText(state) {
      state.searchText = "";
    },
    setActiveGuild(state, guild) {
      localStorage.setItem(
        "activeGuild",
        JSON.stringify({
          id: guild.id,
          name: guild.name,
        })
      );
      state.activeGuild.id = guild.id;
      state.activeGuild.name = guild.name;
    },
  },
  actions: {},
  modules: {
    user,
    users,
    clips,
  },
});
