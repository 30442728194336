<template>
  <v-container fluid class="scrollable">
    <!-- <div>
      <v-avatar color="grey darken-2">
        <v-icon size="36"> mdi-pound </v-icon>
      </v-avatar>
      <h2>Welcome to #home!</h2>
      <p class="text-caption grey--text">
        This is the start of the #home channel
      </p>
    </div> -->

    <v-row class="text-center">
      <v-col cols="12" class="pb-0">
        <h2>Welcome to Clippy</h2>
        <p class="text-caption grey--text">
          This is a brand new, shiny server. <br />
          Here are some steps to help you get started.
        </p>
      </v-col>
      <v-col
        v-for="(c, index) in checklist"
        :key="index"
        cols="12"
        class="pa-0"
      >
        <v-row class="justify-center px-4" no-gutters>
          <v-col cols="12" class="pt-0">
            <v-alert
              :icon="c.prependIcon"
              max-width="300"
              color="#292b2f"
              :class="`mx-auto ${c.link ? 'clickable' : ''}`"
              @click="c.link ? $router.push(c.link) : ''"
            >
              <v-row align="center">
                <v-col class="grow">{{ c.text }}</v-col>
                <v-col class="shrink">
                  <v-icon :color="c.appendColor">{{ c.appendIcon }}</v-icon>
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-divider class="chat-divider my-3"></v-divider>

    <!-- <div class="fancy mt-1">
      <span class="grey--text text--darken-1 caption">{{ date }}</span>
    </div> -->

    <messages v-if="messages.length" keyName="welcome" :items="messages" />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import Messages from "../components/shared/Messages.vue";
export default {
  name: "Welcome",
  components: {
    Messages,
  },
  data: () => ({
    checklist: [
      {
        prependIcon: "mdi-account-multiple-plus",
        prependColor: "purple",
        text: "Invite your friends",
        appendIcon: "mdi-checkbox-marked-circle",
        appendColor: "green",
      },
      {
        prependIcon: "mdi-cast-audio",
        prependColor: "yellow",
        text: "Play some clips",
        appendIcon: "mdi-login-variant",
        appendColor: "blue",
        link: "/clips",
      },
    ],
    messages: [],
  }),
  computed: {
    date() {
      return new Date().toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    ...mapGetters("users", {
      onlineUsers: "onlineUsers",
    }),
  },
  methods: {
    init() {
      var eccentrikit = this.onlineUsers.find(
        (x) => x.username === "eccentrikit"
      );
      if (eccentrikit) {
        this.messages = [
          {
            avatar: eccentrikit.staticAvatarURL,
            username: "Eccentrikit",
            time: new Date().toLocaleString(),
            text: ["!welcome"],
          },
          {
            avatar: require("../assets/clippy.png"),
            username: "Clippy",
            nameColor: "primary",
            bot: true,
            time: new Date().toLocaleString(),
            text: [
              "Hello, this is Clippy v3 - Early Access!",
              "Everything is a work in progress",
              "The theme is intended to be a cheap knock off discord theme because I'm too lazy to come up with something actually unique...",
              "That and it was kinda fun to figure out how to mimic it",
            ],
            delay: 500,
          },
          {
            avatar: eccentrikit.staticAvatarURL,
            username: "Eccentrikit",
            time: new Date().toLocaleString(),
            text: [
              "Feel free to click around and provide feedback",
              "!discord",
            ],
          },
          {
            avatar: require("../assets/clippy.png"),
            username: "Clippy",
            nameColor: "primary",
            bot: true,
            time: new Date().toLocaleString(),
            text: [
              "You can provide feedback in the REAL discord channel:<br/><a class='link' target='blank' href='https://discord.gg/ENFR3RzZQ7'>https://discord.gg/ENFR3RzZQ7</a>",
            ],
            delay: 500,
            serverInvite: true,
          },
        ];
      }
    },
  },
  watch: {
    onlineUsers: "init",
  },
  created() {
    this.init();
  },
};
</script>
