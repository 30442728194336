<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      max-width="500px"
      overlay-opacity=".9"
      scrollable
      :persistent="timer > 0"
    >
      <v-card class="discord-dark-2">
        <v-card-title>
          <span class="text-h5">What's New</span>
          <v-spacer></v-spacer>
          <v-btn
            large
            icon
            plain
            @click="dialog = false"
            :loading="timer > 0"
            :outlined="timer > 0"
          >
            <template v-slot:loader>
              {{ timer }}
            </template>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle class="pb-0 mb-2">
          <span class="text-caption grey--text">October 26, 2021</span>
        </v-card-subtitle>
        <v-card-text class="scrollable thin-scrollbar" style="height: initial">
          <div class="header-lottie-container">
            <lottie-animation
              v-if="showLottie"
              :animationData="require('assets/doodle.json')"
              :loop="true"
              height="200"
              class="rounded header-lottie"
            />
          </div>
          <h2 class="new-features mb-3 mt-6">NEW FEATURES</h2>
          <ul class="grey--text selectable">
            <li>
              <strong>Clip chaining makes a return!</strong> Due to popular
              demand, we have decided to bring back the clip chaining feature.
              Head on over to the #chains channel to start adding chains!
            </li>
          </ul>
          <!-- <h2 class="updates my-3">UPDATES</h2>
            <ul class="grey--text">
              <li>
                <strong>Change Log added.</strong> Now you can see a change log!
              </li>
              <li>
                <strong>More stats available.</strong> Who doesn't like pretty
                charts? A new stats 2 page has been added that breaks down each
                clip's users. Additionally, you can also click the info icon on
                each clip to see it's information as well as stat breakdown
              </li>
              <li>
                <strong>User list overhauled.</strong> The user list is now much
                more dynamic and will update user icons and roles dynamically.
                Clippy also now shows when someone is playing clip in realtime.
              </li>
              <li>
                <strong>New clip notification.</strong> There's now an indicator
                when someone adds new clips. Click the button to load the new
                clips!
              </li>
            </ul> -->
        </v-card-text>

        <v-card-actions class="discord-dark-3 text-caption grey--text">
          <!-- <v-icon color="grey" class="ma-1">mdi-twitter</v-icon>
          <v-icon color="grey" class="ma-1">mdi-facebook</v-icon>
          <v-icon color="grey" class="ma-1">mdi-instagram</v-icon> -->
          <v-btn
            icon
            plain
            href="https://www.patreon.com/clippybot"
            target="_blank"
          >
            <v-icon color="grey" class="ma-1">mdi-patreon</v-icon>
          </v-btn>
          <span class="ml-2">Follow us for more updates!</span>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style scoped>
h2.new-features {
  display: flex;
  align-items: center;
  color: #4fdc7c;
}

h2.new-features::after {
  content: "";
  flex: 1;
  margin-left: 0.5rem;
  height: 1px;
  background-color: #397a51;
}

h2.updates {
  display: flex;
  align-items: center;
  color: #faa81a;
}

h2.updates::after {
  content: "";
  flex: 1;
  margin-left: 0.5rem;
  height: 1px;
  background-color: #ac7c29;
}

.header-lottie-container {
  height: 200px;
  overflow: hidden;
}

.header-lottie {
  margin-top: -150px;
  background-color: #ffffff;
}
</style>

<script>
import LottieAnimation from "lottie-web-vue";

export default {
  name: "ChangeLog",
  props: ["bus"],
  components: {
    LottieAnimation,
  },
  data: () => ({
    dialog: false,
    showLottie: false,
    timer: 0,
  }),
  computed: {},
  watch: {
    dialog(val) {
      if (val) {
        setTimeout(() => {
          this.showLottie = true;
        }, 100);
      }
    },
  },
  methods: {
    countDown() {
      setTimeout(() => {
        this.timer--;
        if (this.timer) this.countDown();
      }, 1000);
    },
  },
  created() {
    this.bus.$on("showChangeLog", () => {
      this.dialog = true;
    });

    var changeLogVersion = 5;

    let changeLogExperienced =
      localStorage.getItem(`changelog-experienced`) || "0";

    if (
      localStorage.getItem(`welcome-experienced-welcome`) == "true" &&
      changeLogVersion > Number(changeLogExperienced)
    ) {
      this.dialog = true;
      this.timer = 5;
      this.countDown();
      localStorage.setItem(`changelog-experienced`, changeLogVersion);
    } else {
      localStorage.setItem(`changelog-experienced`, changeLogVersion);
    }
  },
};
</script>
