<template>
  <div>
    <v-tooltip top color="black">
      <template #activator="{ on: onTooltip, attrs: attrsTooltip }">
        <v-btn
          @click.stop="dialog = true"
          v-on="{ ...onTooltip }"
          v-bind="{ ...attrsTooltip }"
          icon
          color="grey"
        >
          <v-icon>mdi-cog</v-icon>
        </v-btn>
      </template>
      <span>Settings</span>
    </v-tooltip>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="scale-transition"
      scrollable
    >
      <v-card tile class="discord-dark-2">
        <v-toolbar flat color="transparent" v-show="$vuetify.breakpoint.xsOnly">
          <v-toolbar-title
            >Clippy
            <span class="caption grey--text text--darken-2">
              v{{ version }}
            </span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon plain @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="px-0">
          <v-row justify="center" class="fill-height" no-gutters>
            <v-col
              class="discord-dark-3 pt-10"
              v-show="$vuetify.breakpoint.smAndUp"
            >
              <v-row justify="end">
                <v-col class="shrink">
                  <v-tabs
                    center-active
                    hide-slider
                    color="white"
                    vertical
                    v-model="tab"
                    background-color="transparent"
                  >
                    <v-tab> Clippy Nitro </v-tab>
                    <v-divider></v-divider>
                    <v-tab @click.stop="showChangeLog()"> Change Log </v-tab>
                    <v-divider></v-divider>
                    <v-tab :href="logoutURL"
                      ><span class="red--text">Logout</span>
                    </v-tab>
                    <v-divider></v-divider>
                  </v-tabs>
                  <p class="caption grey--text text--darken-2 mt-4 text-center">
                    <v-btn
                      icon
                      plain
                      href="https://www.patreon.com/clippybot"
                      target="_blank"
                    >
                      <v-icon color="grey" class="ma-1">mdi-patreon</v-icon>
                    </v-btn>
                  </p>
                  <p class="caption grey--text text--darken-2 mt-4 text-center">
                    v{{ version }}
                  </p>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              sm="5"
              style="max-width: 800px"
              class="discord-dark-2 pt-sm-10"
            >
              <v-tabs-items v-model="tab" class="discord-dark-2">
                <v-tab-item transition="fade-transition">
                  <v-card color="transparent" flat>
                    <v-container class="pt-0">
                      <v-img :src="require('../assets/nitro.svg')">
                        <v-row>
                          <v-col cols="12" class="justify-center text-center">
                            <div class="text-h5 mt-6 mt-lg-10 mt-xl-16">
                              Clippy
                            </div>
                            <div class="text-h2 font-weight-bold font-italic">
                              Nitro
                            </div>
                            <div class="mt-2">Make Clippy yours</div>
                            <p class="mt-3">
                              Upgrade your clips, personalize your clips,<br />
                              and stand out in your servers.
                            </p>
                            <v-btn
                              color="white"
                              class="indigo--text mr-4"
                              depressed
                              disabled
                            >
                              <v-icon left>mdi-speedometer</v-icon>
                              <span class="text-capitalize">Subscribe</span>
                            </v-btn>
                            <v-btn color="white" class="indigo--text" disabled>
                              <v-icon left>mdi-gift</v-icon>
                              <span class="text-capitalize">Gift</span>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-img>
                      <v-card-title>Also Includes:</v-card-title>
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                          xl="4"
                          v-for="(feat, i) in features"
                          :key="i"
                          class="d-flex flex-column"
                        >
                          <v-card
                            class="discord-dark-4 text-center flex"
                            rounded="lg"
                            flat
                          >
                            <v-card-title
                              :class="`${feat.color}--text d-block font-weight-black`"
                            >
                              {{ feat.title }}
                            </v-card-title>
                            <v-card-subtitle>
                              {{ feat.text }}
                            </v-card-subtitle>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
            <v-col
              class="discord-dark-2 pt-10"
              v-if="$vuetify.breakpoint.smAndUp"
            >
              <v-btn large outlined icon color="grey" @click="dialog = false">
                <v-icon large>mdi-close</v-icon>
              </v-btn>
            </v-col>
            <v-col v-show="$vuetify.breakpoint.xsOnly" class="text-center">
              <v-btn :href="logoutURL" color="red" class="my-4" depressed>
                Logout
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
.v-list.short .v-list-item {
  min-height: 36px;
}
.v-list.short .v-subheader {
  height: 36px;
}
.v-tab--active::before {
  opacity: 0.1 !important;
  background-color: currentColor;
  bottom: 0;
  content: "";
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.theme--dark.v-btn.v-btn--disabled .v-icon,
.theme--dark.v-btn.v-btn--disabled {
  color: rgba(63, 81, 181, 0.3) !important;
}
</style>

<script>
export default {
  name: "Settings",
  props: ["bus"],
  data: () => ({
    dialog: false,
    tab: null,
    features: [
      {
        color: "cyan",
        title: "Upload Clips",
        text: "Customize your server by uploading your own clips",
      },
      {
        color: "orange",
        title: "More Clip Power",
        text: "Use your favorite clips anywhere.",
      },
      {
        color: "blue",
        title: "Support Your Server",
        text: "Get 2 Server Boosts and 30% off extra Boosts.",
      },
      {
        color: "text--lighten-2 pink",
        title: "Clip Chaining",
        text: "Play another clip immediately instead of waiting for the first clip to finish",
      },
      {
        color: "text--lighten-1 deep-purple",
        title: "Less Rate Limiting",
        text: "Play clips more often. Turn that annoyance level to 11.",
      },
      {
        color: "pink",
        title: "HD Clips",
        text: "Better audio bitrate for all your clips.",
      },
    ],
  }),
  computed: {
    version() {
      return process.env.VUE_APP_VERSION;
    },
    logoutURL() {
      return process.env.VUE_APP_API_BASEURL + "/logout";
    },
  },
  watch: {
    tab(val) {
      if (val != 0) {
        this.tab = 0;
      }
    },
  },
  methods: {
    showChangeLog() {
      this.bus.$emit("showChangeLog");
    },
  },
};
</script>
