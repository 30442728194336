<template>
  <v-container fluid class="scrollable">
    <div>
      <v-avatar color="grey darken-2" @click="init()">
        <v-icon size="36"> mdi-pound </v-icon>
      </v-avatar>
      <h2>Welcome to #{{ channelName }}!</h2>
      <p class="text-caption grey--text">
        This is the start of the #{{ channelName }} channel
      </p>
    </div>

    <v-divider class="chat-divider mb-3"></v-divider>

    <messages keyName="clips" :existing="messages" />

    <v-row class="px-1">
      <v-col
        v-for="clip in clips"
        :key="clip.clip"
        xl="3"
        lg="4"
        md="4"
        sm="6"
        cols="12"
        class="d-flex flex-column px-1 py-1"
      >
        <clip :clip="clip" :editable="true" :bus="bus" />
      </v-col>
    </v-row>

    <edit :bus="bus" />
  </v-container>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import Messages from "components/shared/Messages.vue";
import Clip from "components/shared/Clip.vue";
import Edit from "components/shared/Edit.vue";

// import LottieAnimation from "lottie-web-vue";

export default {
  name: "Clips",
  components: {
    Messages,
    // LottieAnimation,
    Clip,
    Edit,
  },
  data: () => ({
    bus: new Vue(),
    messages: [],
    hint: true
  }),
  computed: {
    ...mapState(["searchText", "activeGuild"]),
    ...mapState("user", ["user"]),
    ...mapGetters("clips", {
      recentClips: "recentClips",
      filteredHiddenClips: "filteredHiddenClips",
    }),
    favorite() {
      return this.$route.name == "Favorites";
    },
    popular() {
      return this.$route.name == "Popular";
    },
    recent() {
      return this.$route.name == "Recent";
    },
    clips() {
      return this.filteredHiddenClips;
    },
    date() {
      return new Date().toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    channelName() {
      return (this.$route.params.voice || this.$route.name)
        .toLowerCase()
        .split(" ")
        .join("-");
    },
    foundClipsMessage() {
      return `I've found ${this.clips.length} clip${
        this.clips.length == 1 ? "" : "s"
      } for you!`;
    },
  },
  watch: {
    $route() {
      Vue.set(this.messages[0], "text", [
        `!${this.$route.name.toLowerCase()} ${this.searchText}`,
      ]);
      Vue.set(this.messages[1], "text", [this.foundClipsMessage]);
    },
    searchText(val) {
      Vue.set(this.messages[0], "edited", true);
      Vue.set(this.messages[0], "text", [
        `!${this.$route.name.toLowerCase()} ${val}`,
      ]);
      Vue.set(this.messages[1], "edited", true);
      Vue.set(this.messages[1], "text", [this.foundClipsMessage]);
    }
  },
  methods: {
    ...mapMutations(["enableSearch", "disableSearch"]),
    ...mapActions("clips", {
      getHiddenClips: "getHiddenClips",
      playClip: "playClip",
    }),
    play(clip) {
      if (clip.playing) {
        return;
      }
      this.playClip(clip);
    },
    loadNewClips() {
      this.init();
    },
    init() {
      this.messages.splice(0);
      this.messages.push({
        avatar: this.user.avatarURL,
        username: this.user.display_name || this.user.global_name,
        time: new Date().toLocaleString(),
        text: [`!${this.$route.name.toLowerCase().split(" ").join("-")}`],
      });
      this.getHiddenClips().then(() => {
        this.messages.push({
          avatar: require("assets/clippy.png"),
          username: "Clippy",
          nameColor: "primary",
          bot: true,
          time: new Date().toLocaleString(),
          text: [this.foundClipsMessage],
        });
        this.hint = false;
        this.enableSearch();
      });
    },
  },
  created() {
    this.init();
  },
  destroyed() {
    this.disableSearch();
  },
};
</script>
