<template>
  <v-app id="inspire">
    <login />

    <v-app-bar
      v-if="isActive"
      app
      clipped-right
      height="48"
      class="discord-dark-2"
      elevation="1"
    >
      <v-app-bar-nav-icon @click="nav = !nav" class="hidden-lg-and-up">
      </v-app-bar-nav-icon>
      <v-toolbar-title class="pl-0 hidden-xs-only">
        <v-icon class="mr-1 grey--text">mdi-pound</v-icon>
        {{ channelName }}
      </v-toolbar-title>
      <v-divider vertical class="mx-4 hidden-xs-only"></v-divider>
      <v-responsive class="hidden-xs-only">
        <span class="text-subtitle-1">
          {{ channelTopic }}
        </span>
      </v-responsive>

      <v-spacer></v-spacer>

      <v-tooltip bottom color="black">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="!searchFocused"
            @click="stop"
            v-on="on"
            v-bind="attrs"
            icon
            plain
            color="white"
            :ripple="false"
          >
            <v-icon>mdi-stop-circle-outline</v-icon>
          </v-btn>
        </template>
        <span>Stop</span>
      </v-tooltip>

      <v-tooltip bottom color="black">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="!searchFocused"
            @click="dc"
            v-on="on"
            v-bind="attrs"
            icon
            plain
            color="white"
            :ripple="false"
          >
            <v-icon>mdi-connection</v-icon>
          </v-btn>
        </template>
        <span>Disconnect</span>
      </v-tooltip>

      <favorites :show="searchFocused" />

      <v-tooltip bottom color="black">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="!searchFocused"
            @click="toggleMemberList()"
            v-on="on"
            v-bind="attrs"
            icon
            plain
            color="white"
            :ripple="false"
          >
            <v-icon>mdi-account-multiple</v-icon>
          </v-btn>
        </template>
        <span>Member List</span>
      </v-tooltip>

      <v-responsive
        max-width="220"
        v-show="showSearch || $vuetify.breakpoint.smAndUp"
      >
        <v-text-field
          v-model="mainSearch"
          :disabled="!searchEnabled"
          background-color="discord-dark-5"
          dense
          flat
          hide-details
          class="rounded-lg"
          clearable
          solo
          placeholder="Search"
          :append-icon="mainSearch ? '' : 'mdi-magnify'"
          @click:append="focusSearch"
          @focus="searchOnFocused(true)"
          @blur="searchOnFocused(false)"
          ref="search"
        ></v-text-field>
      </v-responsive>

      <v-tooltip bottom color="black" v-if="$vuetify.breakpoint.xsOnly">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :disabled="!searchEnabled"
            v-show="!searchFocused"
            @click="
              showSearch = true;
              focusSearch();
            "
            v-on="on"
            v-bind="attrs"
            icon
            plain
            color="white"
            :ripple="false"
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </template>
        <span>Search</span>
      </v-tooltip>

      <v-tooltip bottom color="black">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="updatePrompt"
            @click="reload()"
            v-on="on"
            v-bind="attrs"
            icon
            plain
            color="#41B888"
            :ripple="false"
          >
            <v-icon>mdi-tray-arrow-down</v-icon>
          </v-btn>
        </template>
        <span>Update</span>
      </v-tooltip>
    </v-app-bar>

    <v-navigation-drawer
      v-if="isActive"
      v-model="nav"
      :touchless="$vuetify.breakpoint.lgAndUp"
      mobile-breakpoint="1000"
      app
      width="310"
      floating
      class="discord-dark-3"
      style="padding-left: 72px"
    >
      <guilds :mainNav="nav" />

      <navigation />

      <template v-slot:append>
        <v-card class="discord-dark-4">
          <v-card-actions class="py-0">
            <v-row>
              <v-col class="px-0">
                <div class="mt-2 mx-3 pb-1" v-if="voiceChannel">
                  <div class="text-subtitle-2" style="color: #4dd779">
                    <v-icon color="#4DD779" small>mdi-signal-cellular-3</v-icon>
                    <strong>Voice Connected</strong>
                  </div>
                  <div class="text-caption grey--text">
                    {{ voiceChannel.channel }} / {{ voiceChannel.server }}
                  </div>
                </div>
                <v-divider></v-divider>
                <v-list-item class="px-3">
                  <v-list-item-avatar color="grey darken-3">
                    <v-img
                      alt="Avatar"
                      v-if="user && user.avatar"
                      :src="user.avatarURL"
                    ></v-img>
                    <span v-else-if="user && user.username" class="text-h5"
                      >{{ getInitials(user.username) }}
                    </span>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-subtitle v-if="user"
                      >{{ user.username }}
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-row class="shrink mr-0">
                    <v-icon color="grey" class="mr-2">{{
                      deafened ? "mdi-headphones-off" : "mdi-headphones"
                    }}</v-icon>

                    <settings :bus="bus" />
                  </v-row>
                </v-list-item>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </template>
    </v-navigation-drawer>

    <users v-if="isActive" />

    <v-main v-if="isActive" class="discord-dark-2">
      <router-view />
    </v-main>

    <change-log v-if="isAuthenticated" :bus="bus" />
  </v-app>
</template>

<style>
@import "assets/css/site.css";
</style>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import { debounce } from "@utils/utils.js";
import router from "@/router";
import Vue from "vue";

import Login from "components/Login.vue";
import Navigation from "components/Navigation.vue";
import Guilds from "components/Guilds.vue";
import Users from "components/Users.vue";
import Settings from "components/Settings.vue";
import Favorites from "components/shared/Favorites.vue";
import ChangeLog from "components/ChangeLog.vue";

// import utils from "../util/utils"

export default {
  name: "App",
  components: {
    Login,
    Navigation,
    Guilds,
    Users,
    Settings,
    Favorites,
    ChangeLog,
  },
  data: () => ({
    bus: new Vue(),
    nav: null,
    mainSearch: "",
    pinned: false,
    actionTimeoutID: null,
    showSearch: false,
    searchFocused: false,

    voiceChannel: null,
    deafened: false,

    registration: null,
    updatePrompt: false,
  }),
  computed: {
    ...mapState([
      "isAuthenticated",
      "searchEnabled",
      "searchText",
      "activeGuild",
      "socket",
    ]),
    ...mapState({
      user: (s) => s.user.user,
    }),
    ...mapGetters(["isActive"]),
    loginURL() {
      return process.env.VUE_APP_API_BASEURL + "/login";
    },
    channelName() {
      return (this.$route.params.voice || this.$route.name)
        .toLowerCase()
        .split(" ")
        .join("-");
    },
    channelTopic() {
      if (this.$route.params.voice) {
        return `Clips featuring ${this.$route.params.voice}`;
      }
      return this.$route.meta.desc || "Clippy v3 - Early Access";
    },
  },
  watch: {
    activeGuild: {
      deep: true,
      handler() {
        let route = "/";
        if (this.$route.name == "Clips") {
          route = "/clips";
        } else if (this.$route.name == "Favorites") {
          route = "/favorites";
        } else if (this.$route.name == "Popular") {
          route = "/popular";
        } else if (this.$route.name == "Recent") {
          route = "/recent";
        } else if (this.$route.name == "Chains") {
          route = "/chains";
        } else if (this.$route.name == "Hidden Clips") {
          route = "/hiddenclips";
        } else if (this.$route.name == "Prune") {
          route = "/prune";
        } else if (this.$route.name == "Stats") {
          route = "/stats";
        } else if (this.$route.name == "Stats 2") {
          route = "/stats2";
        } else if (this.$route.name == "Log") {
          route = "/log";
        }

        if (this.$route.name != "Welcome") {
          router.push("/");
          this.$nextTick(() => {
            router.push(route);
          });
        }
      },
    },
    searchText(val) {
      this.mainSearch = val;
    },
    mainSearch: debounce(function (newVal) {
      this.$store.commit("setSearchText", newVal);
    }, 300),
  },
  methods: {
    ...mapActions("user", {
      getUser: "getUser",
    }),
    ...mapActions("clips", {
      // getClips: "getClips",
      stopPlaying: "stopPlaying",
      disconnect: "disconnect",
    }),
    ...mapMutations("users", {
      toggleMemberList: "toggleVisible",
    }),
    getInitials(name) {
      return name
        .split(" ")
        .map((n) => n[0])
        .join("");
    },
    focusSearch() {
      this.$nextTick(() => {
        this.$refs.search.focus();
      });
    },
    searchOnFocused(val) {
      if (this.$vuetify.breakpoint.xsOnly) {
        this.searchFocused = val;
        this.showSearch = val;
      }
    },
    stop() {
      if (!this.actionTimeoutID) {
        this.stopPlaying(this.activeGuild.id);
        this.actionTimeoutID = setTimeout(() => {
          this.actionTimeoutID = null;
        }, 250);
      } else {
        clearTimeout(this.actionTimeoutID);
        this.stopPlaying();
        this.actionTimeoutID = null;
      }
    },
    dc() {
      if (!this.actionTimeoutID) {
        this.disconnect(this.activeGuild.id);
        this.actionTimeoutID = setTimeout(() => {
          this.actionTimeoutID = null;
        }, 250);
      } else {
        clearTimeout(this.actionTimeoutID);
        this.disconnect();
        this.actionTimeoutID = null;
      }
    },
    reload() {
      if (this.registration.waiting) {
        // let waiting Service Worker know it should became active
        this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
      }
      // window.location.replace(window.location.href);
    },
  },
  created() {
    var _this = this;

    this.socket.on("channel update", (data) => {
      if (data) {
        this.voiceChannel = data;
      } else {
        this.voiceChannel = null;
      }
    });

    this.socket.on("voice update", (data) => {
      this.deafened = data.deafened;
    });

    // Ctrl + F
    window.addEventListener("keydown", function (e) {
      if (e.keyCode === 114 || (e.ctrlKey && e.keyCode === 70)) {
        e.preventDefault();
        _this.focusSearch();
      }
    });

    // Updater
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.getRegistration().then((reg) => {
        this.registration = reg;
        if (reg.waiting) {
          this.updatePrompt = true;
        }

        reg.addEventListener("updatefound", () => {
          if (reg.installing) {
            // wait until the new Service worker is actually installed (ready to take over)
            reg.installing.addEventListener("statechange", () => {
              if (reg.waiting) {
                console.log("Update available");
                this.updatePrompt = true;
              }
            });
          }
        });
      });
      let refreshing = false;

      // detect controller change and refresh the page
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (!refreshing) {
          window.location.replace(window.location.href);
          refreshing = true;
        }
      });
    }
  },
};
</script>