<template>
  <v-list class="discord-dark-3 pl-0" nav dense expand>
    <template v-for="route in routes">
      <v-list-item
        :key="route.name"
        exact
        :to="route.path.split('/:')[0]"
        class="ml-2 pl-24"
        color="grey darken-2"
      >
        <v-list-item-content>
          <v-list-item-title
            :class="`${
              $route.name === route.name && !$route.params.voice
                ? 'white--text'
                : 'grey--text'
            } text-subtitle-1`"
          >
            <v-icon small color="grey" class="mr-1">mdi-pound</v-icon>
            <span v-text="route.name.toLowerCase().split(' ').join('-')"></span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-group
        v-if="route.name == 'Clips'"
        :key="route.name + 'voices'"
        v-model="voicesGroup"
        :ripple="false"
        append-icon=""
        color="grey darken-2"
        class="pl-0 my-4"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title class="grey--text text-subtitle-1">
              <v-icon>{{
                voicesGroup ? "$expand" : "mdi-chevron-right"
              }}</v-icon>
              <span>Voices</span>
            </v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="item in clipVoices"
          :key="item.voice"
          :to="`/clips/${item.voice.toLowerCase()}`"
          class="ml-2 pl-2"
        >
          <v-list-item-content>
            <v-list-item-title
              :class="`${
                ($route.params.voice || '').toLowerCase() ===
                item.voice.toLowerCase()
                  ? 'white--text'
                  : 'grey--text'
              } text-subtitle-1`"
            >
              <v-icon small color="grey" class="mr-1">mdi-pound</v-icon>
              <span
                v-text="item.voice.toLowerCase().split(' ').join('-')"
              ></span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </template>
  </v-list>
</template>

<style>
.v-list-group__header.v-list-item {
  padding-left: 0;
}
</style>

<script>
import router from "@/router";
import { mapState, mapGetters } from "vuex";

export default {
  name: "Navigation",

  data: () => ({
    voicesGroup: false,
  }),
  computed: {
    ...mapState(["activeGuild"]),
    ...mapGetters("user", ["adminGuilds"]),
    ...mapGetters("clips", {
      clipVoices: "clipVoices",
    }),
    routes() {
      return router.options.routes.filter((r) => r.name && (!((r.meta || {}).adminOnly) || this.isAdmin));
    },
    currentRoute() {
      return this.$route;
    },
    isAdmin() {
      return this.adminGuilds.some((x) => x.id == this.activeGuild.id);
    },
  },
  watch: {
    // voicesGroup(val) {
    //   console.log("Voices", val);
    //   if (!val) {
    //     setTimeout(() => {
    //       this.voicesGroup = true;
    //     }, 100);
    //   }
    // },
  },
  created() {
    if (this.$route.params.voice) {
      this.voicesGroup = true;
    }
  },
};
</script>
