<template>
  <v-card
    class="my-0 flex d-flex flex-column"
    @click="play()"
    :ripple="false"
    :disabled="clip.playing"
    ref="clip-container"
  >
    <div class="d-flex flex-no-wrap justify-space-between">
      <v-card-title
        class="text-h6 py-1 text-truncate"
        v-text="clip.name"
      ></v-card-title>

      <div class="mr-2 d-flex justify-end">
        <v-btn
          v-show="!clip.hide"
          class="my-2"
          icon
          plain
          @click.stop="favorite()"
          :color="clip.favorite ? 'red' : ''"
          :ripple="false"
        >
          <v-icon>mdi-heart</v-icon>
        </v-btn>

        <v-btn
          v-show="clip.hide"
          class="my-2"
          icon
          plain
          @click.stop="hide()"
          :ripple="false"
        >
          <v-icon>mdi-eye</v-icon>
        </v-btn>

        <v-btn
          v-if="editable != true || !clip.modify"
          class="my-2"
          icon
          plain
          @click.stop="showInfo = true"
          :ripple="false"
        >
          <v-icon>mdi-information-outline</v-icon>
        </v-btn>
        <v-menu left v-if="editable == true && clip.modify">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="my-2"
              v-bind="attrs"
              v-on="on"
              icon
              plain
              :ripple="false"
            >
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <!-- <v-list-item @click="favorite()">
              <v-list-item-title
                class="grey--text text--lighten-1 text-subtitle-2"
              >
                Favorite
              </v-list-item-title>
              <v-list-item-action>
                <v-icon :color="clip.favorite ? 'red darken-4' : ''"
                  >mdi-heart</v-icon
                >
              </v-list-item-action>
            </v-list-item> -->
            <v-list-item @click="showInfo = true">
              <v-list-item-title
                class="grey--text text--lighten-1 text-subtitle-2"
              >
                Info
              </v-list-item-title>
              <v-list-item-action>
                <v-icon color="grey">mdi-information-outline</v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-list-item @click="hide()" v-show="!clip.hide">
              <v-list-item-title
                class="grey--text text--lighten-1 text-subtitle-2"
              >
                Hide
              </v-list-item-title>
              <v-list-item-action>
                <v-icon color="grey">mdi-eye-off</v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-list-item @click="showEdit()">
              <v-list-item-title
                class="grey--text text--lighten-1 text-subtitle-2"
              >
                Edit
              </v-list-item-title>
              <v-list-item-action>
                <v-icon color="grey">mdi-pencil</v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-list-item @click="remove()">
              <v-list-item-title
                class="red--text text--lighten-1 text-subtitle-2"
              >
                Delete
              </v-list-item-title>
              <v-list-item-action>
                <v-icon :color="clip.favorite ? 'red' : ''"
                  >mdi-trash-can</v-icon
                >
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>

    <div class="flex d-flex flex-column">
      <v-divider class="mx-4"></v-divider>

      <v-card-text
        class="
          pt-0
          pb-2
          grey--text
          text--lighten-1
          flex
          d-flex
          flex-column
          justify-space-between
        "
      >
        <div class="py-2 text-subtitle-1">
          <v-icon color="grey" dense>mdi-account-voice</v-icon>
          {{ clip.voices.join(" + ") }}
        </div>

        <div v-show="showDescriptions">
          {{ clip.desc }}
        </div>
      </v-card-text>

      <v-overlay absolute v-if="clip.playing">
        <lottie-animation
          style="height: 150px"
          :animationData="require('assets/speakers.json')"
          :loop="true"
        />
      </v-overlay>
      <v-overlay absolute v-if="clip.deafened">
        <lottie-animation
          style="height: 150px"
          :animationData="require('assets/noentry.json')"
          :loop="true"
        />
      </v-overlay>
      <v-overlay absolute v-else-if="clip.error">
        <lottie-animation
          style="height: 150px"
          :animationData="require('assets/error.json')"
          :loop="true"
        />
      </v-overlay>
      <v-dialog width="400" v-model="showInfo">
        <v-card color="#424242">
          <v-card-title>
            <v-icon>mdi-attachment</v-icon><span>{{ clip.name }}</span>
            <v-spacer></v-spacer>
            <v-btn icon plain @click="showInfo = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-subtitle>
            <div>
              <v-tooltip left color="black">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-message-text</v-icon>
                  {{ clip.desc }}
                </template>
                <span>Description</span>
              </v-tooltip>
            </div>
            <div>
              <v-tooltip left color="black">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-account-voice</v-icon>
                  {{ clip.voices.join(" + ") }}
                </template>
                <span>Voices</span>
              </v-tooltip>
            </div>
            <div>
              <v-tooltip left color="black">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                  {{ convertDate(clip.createdDate) }}
                </template>
                <span>Date Added</span>
              </v-tooltip>
            </div>
            <div>
              <v-tooltip left color="black">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-account</v-icon>
                  {{ clip.creatorDisplayName }}
                </template>
                <span>Uploader</span>
              </v-tooltip>
            </div>
            <div>
              <v-tooltip left color="black">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-counter</v-icon>
                  {{ clip.plays }}
                </template>
                <span>Total Plays</span>
              </v-tooltip>
            </div>
            <div v-show="clip.hidden > 0">
              <v-tooltip left color="black">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-eye-off</v-icon>
                  {{ clip.hidden }}
                </template>
                <span>Users Hidden</span>
              </v-tooltip>
            </div>
          </v-card-subtitle>
          <div class="container pa-0 pb-2 flex align-center">
            <apexchart
              v-if="series.length"
              type="pie"
              :options="chartOptions"
              :series="series"
            ></apexchart>
          </div>
          <v-card-actions class="justify-center">
            <v-btn @click.stop="favorite()">
              <v-icon left :color="clip.favorite ? 'red' : ''">
                mdi-heart
              </v-icon>
              Favorite
            </v-btn>
            <v-btn @click.stop="hide()">
              <v-icon left>mdi-eye{{clip.hide ? "" : "-off"}}</v-icon>
              {{clip.hide ? "Unhide" : "Hide"}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-card>
</template>

<style>
.clip-overlay .v-overlay__content {
  height: 100%;
  width: 100%;
}
</style>

<script>
import { mapState, mapActions } from "vuex";
import LottieAnimation from "lottie-web-vue";
import moment from "moment-timezone";

export default {
  name: "Clip",
  components: {
    LottieAnimation,
  },
  props: ["clip", "editable", "bus"],
  data: () => ({
    showMenu: false,
    x: 0,
    y: 0,
    showInfo: false,
    series: [],
    chartOptions: {
      labels: [],
      chart: {
        type: "pie",
      },
      theme: {
        mode: "dark",
      },
      tooltip: {
        theme: "dark",
      },
      colors: [
        "#F44336",
        "#673AB7",
        "#03A9F4",
        "#4CAF50",
        "#FFEB3B",
        "#E91E63",
        "#3F51B5",
        "#00BCD4",
        "#8BC34A",
        "#FFC107",
        "#9C27B0",
        "#2196F3",
        "#009688",
        "#CDDC39",
        "#FF9800",
      ],
    },
  }),
  computed: {
    ...mapState("clips", ["showDescriptions"]),
  },
  watch: {
    showInfo(val) {
      if (val) {
        this.getClipStats(this.clip.clip).then((response) => {
          var data = response.data;
          this.clip.plays = data.reduce((a, b) => a + b.count, 0);
          this.series = data.map((s) => s.count);
          this.chartOptions = {
            ...this.chartOptions,
            ...{
              labels: data.map((l) => l.displayName || l.username),
            },
          };
        });
      }
    },
  },
  methods: {
    ...mapActions("clips", {
      playClip: "playClip",
      favoriteClip: "favoriteClip",
      hideClip: "hideClip",
      deleteClip: "deleteClip",
      getClipStats: "getClipStats",
    }),
    favorite() {
      this.favoriteClip(this.clip);
    },
    hide() {
      this.hideClip(this.clip);
    },
    showEdit() {
      this.bus.$emit("showEdit", this.clip);
    },
    remove() {
      this.deleteClip(this.clip)
        .then(() => {})
        .catch((err) => {
          console.error(err);
        });
    },
    play() {
      if (this.clip.playing || this.showInfo) {
        return;
      }
      this.playClip(this.clip);
    },
    show(e) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    convertDate(date) {
      return moment.utc(date)._d.toLocaleString();
    },
  },
  created() {},
};
</script>
