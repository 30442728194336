import Vue from "vue";

// initial state
const state = {
  users: [],
  visible: null,
};

// getters
const getters = {
  onlineUsers(state) {
    return state.users.filter((u) => u.status);
  },
  offlineUsers() {
    return state.users.filter((u) => !u.status);
  },
};

// actions
const actions = {
  getUsers({ commit }) {
    return new Promise((resolve) => {
      Vue.axios.get("/users").then((response) => {
        commit("setUsers", response.data);
        resolve(response);
      });
    });
  },
};

// mutations
const mutations = {
  setUsers(state, users) {
    state.users = users.sort((a, b) => {
      if (a.username.toLowerCase() < b.username.toLowerCase()) return -1;
      if (a.username.toLowerCase() > b.username.toLowerCase()) return 1;
    });
    state.users.forEach(user => {
      if (user.avatar) {
        user.avatarURL = `https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}?size=256`;
        user.staticAvatarURL = `https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.png?size=256`;
      }
      if (user.banner) {
        user.bannerURL = `https://cdn.discordapp.com/banners/${user.id}/${user.banner}?size=300`
      }

      user.roles = [];
      if (user.rank === null) {
        user.roles.push({ color: "green", name: "Noobie" });
      } else if (user.rank == 1) {
        user.roles.push({ color: "red", name: "Top Spammer" });
      } else if (user.rank <= 5) {
        user.roles.push({ color: "orange", name: "Heavy User" });
      } else if (user.rank <= 10) {
        user.roles.push({ color: "purple", name: "Medium User" });
      } else {
        user.roles.push({ color: "blue", name: "Light User" });
      }

      if (user.contributorRank == 1) {
        user.roles.push({ color: "pink", name: "Top Contributor" });
      } else if (user.contributorRank !== null) {
        user.roles.push({ color: "cyan", name: "Contributor" });
      }
    })
  },
  toggleVisible(state, val) {
    if (typeof val !== "undefined") {
      state.visible = val;
    } else {
      state.visible = !state.visible;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
