<template>
  <v-navigation-drawer
    app
    v-model="drawer"
    clipped
    right
    width="240"
    floating
    class="discord-dark-3"
    :touchless="$vuetify.breakpoint.lgAndUp"
  >
    <v-subheader
      style="height: 24px"
      class="mt-4 text-caption font-weight-medium"
      >BOT &#8212; 1
    </v-subheader>

    <v-list two-line subheader dense class="pb-0">
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        offset-x
        left
        nudge-left="10"
        transition="slide-x-transition"
        rounded="lg"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-list-item v-bind="attrs" v-on="on">
            <v-list-item-icon class="mr-3">
              <v-badge
                avatar
                bottom
                bordered
                offset-x="14"
                offset-y="0"
                color="green"
                class="small"
              >
                <v-avatar size="36">
                  <img alt="Avatar" :src="require('../assets/clippy.png')" />
                </v-avatar>
              </v-badge>
            </v-list-item-icon>
            <v-list-item-content class="pt-0">
              <v-list-item-title>
                <span class="primary--text text-subtitle-1 font-weight-bold">
                  Clippy
                </span>
                <v-chip
                  class="pa-1 font-weight-light"
                  label
                  color="#5865F2"
                  x-small
                >
                  BOT
                </v-chip>
              </v-list-item-title>
              <v-list-item-subtitle
                v-if="playing"
                class="text-caption text-truncate grey--text"
              >
                Playing {{ playing.name }}
              </v-list-item-subtitle>
              <v-list-item-subtitle
                v-else-if="watching"
                class="text-caption text-truncate grey--text"
              >
                Watching <strong>{{ watching.username || currentUser.username }}</strong>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-card color="#18191C">
          <v-toolbar color="#5A5A5B" flat height="10">
            <template v-slot:extension>
              <v-fab-transition>
                <v-btn
                  color="#18191C"
                  fab
                  x-large
                  dark
                  absolute
                  bottom
                  left
                  depressed
                  :ripple="false"
                >
                  <v-avatar size="60">
                    <img alt="Avatar" :src="require('../assets/clippy.png')" />
                  </v-avatar>
                </v-btn>
              </v-fab-transition>
            </template>
          </v-toolbar>
          <v-list color="#18191C">
            <v-list-item class="mt-8">
              <v-list-item-content>
                <v-list-item-title class="text-h6">
                  Clippy
                  <v-chip
                    class="pa-1 font-weight-light"
                    label
                    color="#5865F2"
                    x-small
                  >
                    BOT
                  </v-chip>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider class="profile-divider mx-4"></v-divider>

          <v-list color="#18191C">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-subtitle-2 grey--text">
                  <strong>ROLES</strong>
                </v-list-item-title>
                <v-list-item-title class="mt-3">
                  <v-chip color="#292B2F" text-color="white" small label>
                    <v-icon color="red" left>mdi-circle</v-icon>
                    Best Bot
                  </v-chip>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-list>

    <v-skeleton-loader
      v-if="users.length === 0"
      class="mt-2"
      type="heading,list-item-avatar,list-item-avatar"
    ></v-skeleton-loader>

    <v-subheader
      v-if="onlineUsers.length"
      class="text-caption font-weight-medium"
    >
      USERS &#8212; {{ onlineUsers.length }}
    </v-subheader>
    <v-list subheader dense class="pb-0">
      <v-menu
        v-for="user in onlineUsers"
        :key="user.id"
        v-model="user.menu"
        :close-on-content-click="false"
        offset-x
        left
        nudge-left="10"
        transition="slide-x-transition"
        rounded="lg"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-list-item v-bind="attrs" v-on="on" class="py-1">
            <v-list-item-icon class="my-0 mr-3">
              <v-badge
                avatar
                bottom
                bordered
                offset-x="14"
                offset-y="0"
                :color="`${
                  getTimeDifference(user.lastClipTime) < 5
                    ? 'green'
                    : getTimeDifference(user.lastActiveTime) > 5
                    ? 'red darken-3'
                    : 'orange darken-3'
                }`"
                class="small"
              >
                <v-avatar color="grey" size="36">
                  <v-img
                    v-if="user.avatar"
                    :src="user.staticAvatarURL"
                    :id="`avatar-${user.id}`"
                  ></v-img>
                  <v-icon v-else>mdi-account</v-icon>
                </v-avatar>
              </v-badge>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                class="grey--text text-subtitle-1 font-weight-bold"
              >
                {{ user.displayName || user.username }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-card color="#18191C">
          <v-toolbar
            :color="user.staticAvatarURL && getAverageRGB(user) ? user.bannerColor : '#9E9E9E'"
            flat
            :height="user.banner ? '' : 10"
            :src="user.banner ? user.bannerURL : ''"
          >
            <template v-slot:extension>
              <v-fab-transition>
                <v-btn
                  color="#18191C"
                  fab
                  x-large
                  dark
                  absolute
                  bottom
                  left
                  depressed
                  :ripple="false"
                >
                  <v-avatar color="grey" size="60">
                    <v-img v-if="user.avatar" :src="user.avatarURL"></v-img>
                    <v-icon v-else>mdi-account</v-icon>
                  </v-avatar>
                </v-btn>
              </v-fab-transition>
            </template>
          </v-toolbar>
          <v-list color="#18191C">
            <v-list-item class="mt-8">
              <v-list-item-content>
                <v-list-item-title class="text-h6">
                  {{ user.displayName || user.username }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider class="profile-divider mx-4"></v-divider>

          <v-list color="#18191C">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-subtitle-2 grey--text">
                  <strong>ROLES</strong>
                </v-list-item-title>
                <v-list-item-title class="mt-3">
                  <v-chip
                    color="#292B2F"
                    text-color="white"
                    small
                    label
                    v-for="(role, roleIndex) in user.roles"
                    :key="roleIndex"
                    class="mr-1"
                  >
                    <v-icon :color="role.color" left>mdi-circle</v-icon>
                    {{ role.name }}
                  </v-chip>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-list>

    <v-subheader
      v-if="offlineUsers.length"
      class="text-caption font-weight-medium"
    >
      OFFLINE &#8212; {{ offlineUsers.length }}
    </v-subheader>
    <v-list subheader dense class="pb-0">
      <v-list-item v-for="user in offlineUsers" :key="user.id" class="mb-2">
        <v-list-item-icon class="my-0 mr-3">
          <v-badge
            avatar
            bottom
            bordered
            offset-x="14"
            offset-y="0"
            class="small"
            color="grey darken-1"
          >
            <v-avatar color="grey" size="36">
              <v-img v-if="user.avatar" :src="user.staticAvatarURL"></v-img>
              <v-icon v-else>mdi-account</v-icon>
            </v-avatar>
          </v-badge>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="grey--text text-subtitle-1 font-weight-bold"
            >{{ user.displayName || user.username }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<style>
.v-badge.small .v-badge__badge {
  border-radius: 8px;
  height: 16px;
  width: 16px;
  min-width: 0;
  padding: 0;
}
.v-badge.small.v-badge--bordered .v-badge__badge::after {
  border-width: 3px !important;
  border-color: #2f3136 !important;
}
</style>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import moment from "moment-timezone";

export default {
  name: "Navigation",

  data: () => ({
    drawer: null,
    menu: false,
  }),
  computed: {
    ...mapState(["isAuthenticated", "socket"]),
    ...mapState("clips", ["playing", "watching"]),
    ...mapState("users", ["users", "visible"]),
    ...mapState({
      currentUser: (s) => s.user.user,
    }),
    // ...mapState({
    //   users: (s) => s.users.users,
    // }),
    ...mapGetters("user", ["avatarURL"]),
    ...mapGetters("users", {
      onlineUsers: "onlineUsers",
      offlineUsers: "offlineUsers",
    }),
  },
  watch: {
    visible(val) {
      this.drawer = val;
    },
    drawer(val) {
      this.toggleMemberList(val);
    },
  },
  methods: {
    ...mapActions("users", {
      getUsers: "getUsers",
    }),
    ...mapMutations("users", {
      toggleMemberList: "toggleVisible",
    }),
    ...mapMutations("clips", {
      setClipPlaying: "setClipPlaying",
      setClipNotPlaying: "setClipNotPlaying",
      setWatching: "setWatching",
    }),
    getTimeDifference(date) {
      return moment().diff(
        moment.tz(moment.utc(date), moment.tz.guess()),
        "minutes"
      );
    },
    loop() {
      this.getUsers();
      setTimeout(() => {
        this.loop();
      }, 30 * 1000);
    },
    getAverageRGB(user) {
      var defaultRGB = "#9E9E9E";
      if (user.staticAvatarURL == null) {
        user.bannerColor = defaultRGB;
        return;
      }

      var imgEl = document.createElement("img");
      imgEl.crossOrigin = "Anonymous";
      imgEl.src = user.staticAvatarURL;
      imgEl.onload = function () {
        var blockSize = 1, // only visit every 5 pixels
          canvas = document.createElement("canvas"),
          context = canvas.getContext && canvas.getContext("2d"),
          data,
          width,
          height,
          i = -4,
          length,
          rgb = { r: 0, g: 0, b: 0 },
          count = 0;

        if (!context) {
          user.bannerColor = defaultRGB;
          return;
        }

        height = canvas.height =
          imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height;
        width = canvas.width =
          imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width;

        context.drawImage(imgEl, 0, 0);
        try {
          data = context.getImageData(0, 0, width, height);
        } catch (e) {
          /* security error, img on diff domain */
          user.bannerColor = defaultRGB;
          return;
        }
        length = data.data.length;

        while ((i += blockSize * 4) < length) {
          ++count;
          rgb.r += data.data[i];
          rgb.g += data.data[i + 1];
          rgb.b += data.data[i + 2];
        }

        // ~~ used to floor values
        rgb.r = ~~(rgb.r / count);
        rgb.g = ~~(rgb.g / count);
        rgb.b = ~~(rgb.b / count);

        user.bannerColor = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 1)`;
        return;
      };
      return true
    },
  },
  created() {
    if (this.isAuthenticated) {
      this.loop();

      this.socket.on("user update", () => {
        this.getUsers();
      });

      this.socket.on("playing", (data) => {
        this.setClipPlaying(data);
        // console.log("playing:", data)
      });

      this.socket.on("stopping", (data) => {
        this.setClipNotPlaying();
        this.setWatching(data);
        // console.log("stopping")
      });
    }
  },
};
</script>
