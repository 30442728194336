<template>
  <v-row justify="center" no-gutters>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
      overlay-opacity=".7"
    >
      <v-card class="discord-dark-2">
        <v-card-title>
          <span class="text-h5">Edit Clip</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" :disabled="editing">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="name"
                    label="Name"
                    outlined
                    :rules="[rules.required, rules.nameSize]"
                    counter="20"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="desc"
                    label="Description/Transcript"
                    required
                    outlined
                    :rules="[rules.required, rules.descSize]"
                    counter="50"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-combobox
                    v-model="voices"
                    :items="existingVoices"
                    :search-input.sync="voiceSearch"
                    hide-selected
                    label="Add some sources"
                    hint="Who's featured in this wonderful clip? Ex. Dan, Jimmy, The Lich King, Some hobo, etc."
                    multiple
                    persistent-hint
                    outlined
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            No results matching "<strong>{{
                              voiceSearch
                            }}</strong
                            >". Press <kbd>enter</kbd> to create a new one
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <template
                      v-slot:selection="{ attrs, item, parent, selected }"
                    >
                      <v-chip v-bind="attrs" :input-value="selected" small>
                        <span class="pr-2">
                          {{ item }}
                        </span>
                        <v-icon small @click="parent.selectItem(item)">
                          $delete
                        </v-icon>
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="discord-dark-3">
          <div class="red--text">{{ message }}</div>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false"> Cancel </v-btn>
          <v-btn depressed color="primary" @click="submit" :loading="editing">
            Edit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
// import LottieAnimation from "lottie-web-vue";

export default {
  name: "Edit",
  props: ["bus"],
  components: {
    // LottieAnimation,
  },
  data: () => ({
    dialog: false,

    clip: "",
    name: "",
    desc: "",
    voices: [],
    voiceSearch: null,

    message: "",
    editing: false,

    rules: {
      required: (value) => !!value || "Required.",
      fileSize: (value) => (value && value.size <= 200 * 1024) || "Max 200kb",
      nameSize: (value) => (value && value.length <= 20) || "Max Length 20",
      descSize: (value) => (value && value.length <= 50) || "Max Length 50",
    },
  }),
  computed: {
    ...mapState(["activeGuild"]),
    ...mapGetters("clips", {
      existingVoices: "voices",
    }),
  },
  methods: {
    ...mapActions("clips", {
      editClip: "editClip",
    }),
    async submit() {
      var val = this.$refs.form.validate();
      if (val) {
        // console.log(this.name.replace(/[\W_]+/g, " ").replace(/ /g, "_"));

        this.editClip({
          clip: this.clip,
          name: this.name,
          desc: this.desc,
          voices: this.voices,
        })
          .then(() => {
            this.dialog = false;
          })
          .catch((err) => {
            console.error(err);
            this.message = "Ruh oh something went wrong...";
          })
          .then(() => (this.editing = false));
      }
    },
  },
  created() {
    this.bus.$on("showEdit", (clip) => {
      this.dialog = true;
      this.clip = clip.clip;
      this.name = clip.name;
      this.desc = clip.desc;
      this.voices = clip.voices;
    });
  },
};
</script>
