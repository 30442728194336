import Vue from "vue";
import VueRouter from "vue-router";
import Welcome from "../views/Welcome.vue";
import Clips from "../views/Clips.vue";
import Chains from "../views/Chains.vue";
import HiddenClips from "../views/HiddenClips.vue";
import Prune from "../views/Prune.vue";
import Stats from "../views/Stats.vue";
import Stats2 from "../views/Stats2.vue";
import Log from "../views/Log.vue";
// import Favorites from "../views/Favorites.vue";
// import About from "../views/About.vue";

import store from "../store/index.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Welcome",
    component: Welcome,
  },
  {
    path: "/favorites",
    name: "Favorites",
    meta: {
      desc: "Favorite Clips",
    },
    component: Clips,
  },
  {
    path: "/popular",
    name: "Popular",
    meta: {
      desc: "Popular Clips",
    },
    component: Clips,
  },
  {
    path: "/recent",
    name: "Recent",
    meta: {
      desc: "Recently Added Clips",
    },
    component: Clips,
  },
  {
    path: "/clips/:voice?",
    name: "Clips",
    meta: {
      desc: "All Clips",
    },
    component: Clips,
  },
  {
    path: "/chains",
    name: "Chains",
    meta: {
      desc: "Chains",
    },
    component: Chains,
  },
  {
    path: "/hiddenclips",
    name: "Hidden Clips",
    meta: {
      desc: "Hidden Clips",
    },
    component: HiddenClips,
  },
  {
    path: "/prune",
    name: "Prune",
    meta: {
      desc: "Prune unpopular clips",
      adminOnly: true
    },
    component: Prune,
  },
  {
    path: "/stats/:clip?",
    name: "Stats",
    meta: {
      desc: "Statistics",
    },
    component: Stats,
  },
  {
    path: "/stats2/:clip?",
    name: "Stats 2",
    meta: {
      desc: "Statistics",
    },
    component: Stats2,
  },
  {
    path: "/log",
    name: "Log",
    meta: {
      desc: "Usage Log",
      ownerOnly: true
    },
    component: Log,
  },

  // {
  //   path: "/about",
  //   name: "About",
  //   component: About,
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   // component: () => import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
  { path: "*", redirect: "/" },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `${to.name} - Clippy`;

  if(!((to.meta || {}).adminOnly) || store.getters["user/adminGuilds"].some((x) => x.id == store.state.activeGuild.id)) {
    next();
  } else {
    next("/");
  }
});

export default router;
