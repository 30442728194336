import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VueAxios from "vue-axios";
import VueApexCharts from "vue-apexcharts";

Vue.config.productionTip = false;
axios.defaults.withCredentials = true;

axios.defaults.baseURL = process.env.VUE_APP_API_BASEURL;

Vue.use(VueAxios, axios);
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
