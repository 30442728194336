import Vue from "vue";
import moment from "moment";

// import { groupBy } from "@utils/utils";

// initial state
const state = {
  search: "",
  clips: [],
  hiddenClips: [],
  chains: [],
  playing: null,
  playingTimer: null,
  lastGet: null,
  watching: null,
  showNewClips: false,
  showDescriptions: localStorage.getItem("showDescriptions") === "true"
};

// getters
const getters = {
  clipsCount: (state) => {
    return state.clips.length;
  },
  filteredClips: (state, getters, rootState) => {
    return state.clips.filter((clip) => {
      return (!clip.hide) && (
        clip.name.toLowerCase().includes(rootState.searchText.toLowerCase())
        || clip.desc.toLowerCase().includes(rootState.searchText.toLowerCase())
        // || clip.voices.find((v) => v.toLowerCase().includes(rootState.searchText.toLowerCase()))
      );
    });
  },
  filteredHiddenClips: (state, getters, rootState) => {
    return state.hiddenClips.filter((clip) => {
      return (clip.hide) && (
        clip.name.toLowerCase().includes(rootState.searchText.toLowerCase())
        || clip.desc.toLowerCase().includes(rootState.searchText.toLowerCase())
        // || clip.voices.find((v) => v.toLowerCase().includes(rootState.searchText.toLowerCase()))
      );
    });
  },
  filteredFavoriteClips: (state, getters, rootState) => {
    return state.clips.filter((clip) => {
      return (!clip.hide) && (
        clip.favorite &&
        (clip.name.toLowerCase().includes(rootState.searchText.toLowerCase())
          || clip.desc.toLowerCase().includes(rootState.searchText.toLowerCase())
          // || clip.voices.find((v) => v.toLowerCase().includes(rootState.searchText.toLowerCase()))
        )
      );
    });
  },
  filteredPopularClips: (state, getters, rootState) => {
    return getters.popularClips.filter((clip) => {
      return (!clip.hide) && (
        clip.name.toLowerCase().includes(rootState.searchText.toLowerCase())
        || clip.desc.toLowerCase().includes(rootState.searchText.toLowerCase())
        // || clip.voices.find((v) => v.toLowerCase().includes(rootState.searchText.toLowerCase()))
      );
    });
  },
  filteredRecentClips: (state, getters, rootState) => {
    return getters.recentClips.filter((clip) => {
      return (!clip.hide) && (
        clip.name.toLowerCase().includes(rootState.searchText.toLowerCase())
        || clip.desc.toLowerCase().includes(rootState.searchText.toLowerCase())
        // || clip.voices.find((v) => v.toLowerCase().includes(rootState.searchText.toLowerCase()))
      );
    });
  },
  favoriteClips: (state) => {
    return state.clips.filter((clip) => clip.favorite);
  },
  popularClips: (state) => {
    var result = [];

    state.clips.forEach((c) => {
      result.push({ ...c });
    });

    result.sort((a, b) => {
      return b.plays - a.plays;
    }).splice(50, state.clips.length - 50)

    return result;
  },
  recentClips: (state) => {
    var result = [];

    state.clips.forEach((c) => {
      result.push({ ...c });
    });

    result.sort((a, b) => {
      return moment(b.createdDate) - moment(a.createdDate);
    }).splice(50, state.clips.length - 50);

    return result;
  },
  getClip: (state) => (clip) => {
    if (clip) return state.clips.find((x) => x.clip === clip);
    else return {};
  },
  voices: (state) => {
    return [...new Set(state.clips.map((item) => item.voices).flat())].sort((a, b) => {
      if (a.toLowerCase() < b.toLowerCase()) return -1;
      if (a.toLowerCase() > b.toLowerCase()) return 1;
    });
  },
  clipVoices: (state) => {
    var result = [];

    state.clips.forEach((c) => {
      if (!c.hide) {
        c.voices.forEach((v) => {
          var item = result.find((r) => r.voice == v);
          if (item) {
            item.clips.push(c);
          } else {
            result.push({
              voice: v,
              clips: [c],
            });
          }
        });
      }
    });

    return result.sort((a, b) => {
      if (a.voice.toLowerCase() < b.voice.toLowerCase()) return -1;
      if (a.voice.toLowerCase() > b.voice.toLowerCase()) return 1;
    });
  },
  filteredClipVoices: (state, getters, rootState) => {
    var result = [];

    state.clips
      .filter((clip) => {
        return (!clip.hide) && (
          clip.name.toLowerCase().includes(rootState.searchText.toLowerCase()) ||
          clip.desc.toLowerCase().includes(rootState.searchText.toLowerCase()) ||
          clip.voices.find((v) => v.toLowerCase().includes(rootState.searchText.toLowerCase()))
        );
      })
      .forEach((c) => {
        c.voices.forEach((v) => {
          var item = result.find((r) => r.voice == v);
          if (item) {
            item.clips.push(c);
          } else {
            result.push({
              voice: v,
              clips: [c],
            });
          }
        });
      });

    return result.sort((a, b) => {
      if (a.voice < b.voice) return -1;
      if (a.voice > b.voice) return 1;
    });
  },
  favoriteClipVoices: (state) => {
    var result = [];

    state.clips.forEach((c) => {
      if (c.favorite) {
        c.voices.forEach((v) => {
          var item = result.find((r) => r.voice == v);
          if (item) {
            item.clips.push(c);
          } else {
            result.push({
              voice: v,
              clips: [c],
            });
          }
        });
      }
    });

    return result.sort((a, b) => {
      if (a.voice < b.voice) return -1;
      if (a.voice > b.voice) return 1;
    });
  },

  filteredChains: (state, getters, rootState) => {
    return state.chains.filter((chain) => {
      return (
        chain.name.toLowerCase().includes(rootState.searchText.toLowerCase())
      );
    });
  },
};

// actions
const actions = {
  getClipStats({ rootState }, clip) {
    return new Promise((resolve) => {
      Vue.axios.get(`/clipstats/${rootState.activeGuild.id}/${clip}`).then((response) => {
        resolve(response);
      });
    });
  },
  getStats({ rootState }) {
    return new Promise((resolve) => {
      Vue.axios.get(`/stats/${rootState.activeGuild.id}`).then((response) => {
        resolve(response);
      });
    });
  },
  getStats2({ rootState }) {
    return new Promise((resolve) => {
      Vue.axios.get(`/stats2/${rootState.activeGuild.id}`).then((response) => {
        resolve(response);
      });
    });
  },
  getLog({ rootState }, timestamp) {
    return new Promise((resolve) => {
      var formattedTimestamp = moment(timestamp).format("YYYY-MM-DD HH:mm:ss");
      Vue.axios.get(`/log/${rootState.activeGuild.id}/${formattedTimestamp}`).then((response) => {
        resolve(response);
      });
    });
  },
  getClips({ commit, state, rootState }) {
    if (new Date().getTime() - new Date(state.lastGet).getTime() > 100) {
      state.lastGet = new Date();
      commit("setClips", []);
      return new Promise((resolve) => {
        Vue.axios.get(`/clips/${rootState.activeGuild.id}`).then((response) => {
          commit("setClips", response.data);
          resolve(response);
        });
      });
    } else
      return new Promise((resolve) => {
        resolve("Too Soon");
      });
  },
  getHiddenClips({ commit, rootState }) {
    commit("setHiddenClips", []);
    return new Promise((resolve) => {
      Vue.axios.get(`/hiddenclips/${rootState.activeGuild.id}`).then((response) => {
        commit("setHiddenClips", response.data);
        resolve(response);
      });
    });
  },
  getPruneList({ rootState }) {
    return new Promise((resolve) => {
      Vue.axios.get(`/prunelist/${rootState.activeGuild.id}`).then((response) => {
        resolve(response);
      });
    });
  },
  uploadClip({ dispatch }, clip) {
    return new Promise((resolve, reject) => {
      Vue.axios
        .post("/upload", clip)
        .then((response) => {
          dispatch("getClips");
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  editClip({ dispatch }, clip) {
    return new Promise((resolve, reject) => {
      Vue.axios
        .post("/edit", clip)
        .then((response) => {
          dispatch("getClips");
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteClip({ commit }, clip) { // eslint-disable-line
    return new Promise((resolve, reject) => {
      Vue.axios
        .delete(`/delete/${clip.clip}`)
        .then((response) => {
          commit("setClipHide", { clip, hide: true });
          // dispatch("getClips");
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  playClip({ commit, state }, clip) {
    return new Promise((resolve, reject) => {
      var c = state.clips.find(x => x.clip === clip.clip)
      c.playing = true;
      clearTimeout(state.playingTimer);
      commit("setClipPlaying", clip);
      Vue.axios
        .post(`/play`, { clip: clip.clip })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit("setClipNotPlaying");
          c.error = true;
          if (err.response && err.response.status == 409)
            c.deafened = true;
          setTimeout(() => {
            c.error = false;
            c.deafened = false
          }, 2000);
          reject(err);
        })
        .then(() => {
          c.playing = false;
        });
    });
  },
  getChains({ commit, state, rootState }) { // eslint-disable-line
    commit("setChains", []);
    return new Promise((resolve) => {
      Vue.axios.get(`/chains/${rootState.activeGuild.id}`).then((response) => {
        commit("setChains", response.data);
        resolve(response);
      });
    });
  },
  addChain({ rootState, dispatch }, chain) {
    return new Promise((resolve, reject) => {
      chain.guildID = rootState.activeGuild.id;
      Vue.axios
        .post("/addchain", chain)
        .then((response) => {
          dispatch("getChains");
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  editChain({ dispatch }, chain) {
    return new Promise((resolve, reject) => {
      Vue.axios
        .post("/editchain", chain)
        .then((response) => {
          dispatch("getChains");
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteChain({ dispatch }, chainID) {
    return new Promise((resolve, reject) => {
      Vue.axios
        .delete(`/deletechain/${chainID}`)
        .then((response) => {
          dispatch("getChains");
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  playChain({ commit, state }, chain) { // eslint-disable-line
    return new Promise((resolve, reject) => {
      Vue.axios
        .post(`/playchain`, { chain: chain })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        })
    });
  },
  favoriteClip({ commit }, clip) {
    return new Promise((resolve, reject) => {
      Vue.axios
        .get(`/favorite/${clip.clip}`)
        .then((response) => {
          let favorite = response.data.favorite;
          commit("setClipFavorite", { clip, favorite });
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  hideClip({ commit }, clip) {
    return new Promise((resolve, reject) => {
      Vue.axios
        .get(`/hide/${clip.clip}`)
        .then((response) => {
          let hide = response.data.hide;
          commit("setClipHide", { clip, hide });
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  stopPlaying({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.axios
        .get(`/stop`)
        .then((response) => {
          commit("setClipNotPlaying");
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  disconnect({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.axios
        .get(`/disconnect`)
        .then((response) => {
          commit("setClipNotPlaying");
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  setClips(state, clips) {
    clips.forEach((c) => {
      c.playing = false;
      c.error = false;
      c.deafened = false;
      c.voices = c.voices.split(",");
      c.hide = false;
    });
    state.clips = clips.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
    });
    state.showNewClips = false;
  },
  setHiddenClips(state, clips) {
    clips.forEach((c) => {
      c.playing = false;
      c.error = false;
      c.voices = c.voices.split(",");
      c.hide = true;
    });
    state.hiddenClips = clips.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
    });
  },
  setClipPlaying(state, clip) {
    state.playing = clip;
    state.watching = clip;
  },
  setClipNotPlaying(state) {
    state.playing = null;
  },
  setWatching(state, user) {
    state.watching = user;
  },
  setClipFavorite(state, { clip, favorite }) {
    state.clips.find(x => x.clip === clip.clip).favorite = favorite;
  },
  setClipHide(state, { clip, hide }) {
    (state.clips.find(x => x.clip === clip.clip) || {}).hide = hide;
    (state.hiddenClips.find(x => x.clip === clip.clip) || {}).hide = false
  },
  setShowNewClips(state) {
    state.showNewClips = true;
  },
  setChains(state, chains) {
    let uniqueChains = []
    chains.forEach((c) => {
      var chain = uniqueChains.find(x => x.chainID == c.chainID)
      if (chain) {
        chain.clips.push({
          clip: c.clip,
          name: c.name,
          desc: c.desc,
          chainOrder: c.chainOrder
        })
      } else {
        uniqueChains.push({
          chainID: c.chainID,
          name: c.chain,
          clips: [{
            clip: c.clip,
            name: c.name,
            desc: c.desc,
            chainOrder: c.chainOrder
          }]
        })
      }
    })

    uniqueChains.forEach((c) => {
      c.playing = false;
      c.error = false;
    });

    state.chains = uniqueChains
  },
  setShowDescriptions(state, value) {
    state.showDescriptions = value;
    localStorage.setItem("showDescriptions", value);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
