<template>
  <v-container fluid class="scrollable">
    <div>
      <v-avatar color="grey darken-2" @click="init()">
        <v-icon size="36"> mdi-pound </v-icon>
      </v-avatar>
      <h2>Welcome to #{{ channelName }}!</h2>
      <p class="text-caption grey--text">
        This is the start of the #{{ channelName }} channel
      </p>
    </div>

    <v-divider class="chat-divider mb-3"></v-divider>

    <messages keyName="stats" :items="messages" />

    <apexchart
      style="border-left: red 4px solid; border-radius: 4px; background-color: rgb(66, 66, 66)"
      v-if="clips.length"
      type="bar"
      :height="height"
      :options="chartOptions"
      :series="series"
      class="mr-4"
    ></apexchart>
  </v-container>
</template>

<script>
import Messages from "../components/shared/Messages.vue";
import { mapState, mapActions } from "vuex";
export default {
  name: "Stats",
  components: {
    Messages,
  },
  data: () => ({
    messages: [],
    clips: [],
    series: [],
    chartOptions: {
      theme: {
        mode: "dark",
        palette: "palette1",
      },
      chart: {
        type: "bar",
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        position: "top",
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      xaxis: {
        categories: [],
      },
    },
  }),
  computed: {
    ...mapState("user", ["user"]),
    channelName() {
      return (this.$route.params.clip || this.$route.name)
        .toLowerCase()
        .split(" ")
        .join("-");
    },
    height() {
      return this.clips.length * 20;
    },
  },
  methods: {
    ...mapActions("clips", {
      getStats: "getStats",
    }),
    init() {
      this.messages.splice(0)
      this.messages.push({
        avatar: this.user.avatarURL,
        username: this.user.display_name || this.user.global_name,
        time: new Date().toLocaleString(),
        text: ["!stats"],
      });
      this.getStats().then((response) => {
        this.messages.push({
          avatar: require("../assets/clippy.png"),
          username: "Clippy",
          nameColor: "primary",
          bot: true,
          time: new Date().toLocaleString(),
          text: ["Here you go:"],
          delay: 500,
        });
        this.clips = response.data;

        this.clips.sort((a, b) => {
          return b.count - a.count;
        });

        let plays = this.clips.map((c) => c.count);
        let users = this.clips.map((c) => c.users);

        this.chartOptions.xaxis.categories = [];

        this.clips.forEach((c) => {
          this.chartOptions.xaxis.categories.push(c.name);
        });

        this.series = [
          { name: "Plays", data: plays },
          { name: "Users", data: users },
        ];
      });
    },
  },
  created() {
    this.init();
  },
};
</script>
