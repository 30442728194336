<template>
  <v-row justify="center" no-gutters>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
      overlay-opacity=".7"
    >
      <v-card class="discord-dark-2">
        <v-card-title>
          <span class="text-h5">{{ chainID ? "Edit" : "Add" }} Chain</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" :disabled="editing">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="name"
                    label="Name"
                    outlined
                    :rules="[rules.required, rules.nameSize]"
                    counter="20"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  v-for="(clip, index) in chainClips"
                  :key="index"
                >
                  <v-autocomplete
                    :items="clips"
                    :filter="filterClip"
                    item-text="name"
                    item-value="clip"
                    label="Clip"
                    :rules="[rules.required]"
                    v-model="chainClips[index]"
                    outlined
                    append-outer-icon="mdi-close"
                    @click:append-outer="removeClip(index)"
                  >
                    <template v-slot:selection="data">
                      {{ data.item.name }}
                      <span class="ml-1 grey--text"
                        >({{ data.item.voices.join(" + ") }})</span
                      >
                    </template>
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ data.item.name }}
                            <span class="grey--text"
                              >({{ data.item.voices.join(" + ") }})</span
                            >
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ data.item.desc }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col v-show="chainClips.length < 5">
                  <v-btn @click="addClip()"
                    ><v-icon left>mdi-plus</v-icon>Add Clip</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="discord-dark-3">
          <div class="red--text">{{ message }}</div>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false"> Cancel </v-btn>
          <v-btn depressed color="primary" @click="submit()" :loading="editing">
            {{ chainID ? "Edit" : "Add" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapActions } from "vuex";
// import LottieAnimation from "lottie-web-vue";

export default {
  name: "Edit",
  props: ["bus"],
  components: {
    // LottieAnimation,
  },
  data: () => ({
    dialog: false,

    chain: "",
    name: "",
    chainID: null,
    chainClips: [],

    message: "",
    editing: false,

    rules: {
      required: (value) => !!value || "Required.",
      nameSize: (value) => (value && value.length <= 20) || "Max Length 20",
    },
  }),
  computed: {
    ...mapState(["activeGuild"]),
    ...mapState("clips", {
      clips: "clips",
    }),
  },
  methods: {
    ...mapActions("clips", {
      addChain: "addChain",
      editChain: "editChain",
    }),
    addClip() {
      if (this.chainClips.length < 5) this.chainClips.push(this.clips[0].clip);
    },
    removeClip(index) {
      this.chainClips.splice(index, 1);
    },
    edit() {
      var val = this.$refs.form.validate();
      if (val) {
        this.editChain({
          chainID: this.chainID,
          name: this.name,
          clips: this.chainClips,
        })
          .then(() => {
            this.dialog = false;
          })
          .catch((err) => {
            console.error(err);
            this.message = "Ruh oh something went wrong...";
          })
          .then(() => (this.editing = false));
      }
    },
    add() {
      var val = this.$refs.form.validate();
      if (val) {
        this.addChain({
          name: this.name,
          clips: this.chainClips,
        })
          .then(() => {
            this.dialog = false;
          })
          .catch((err) => {
            console.error(err);
            this.message = "Ruh oh something went wrong...";
          })
          .then(() => (this.editing = false));
      }
    },
    submit() {
      if (this.chainID) this.edit();
      else this.add();
    },
    filterClip(item, searchText) {
      return item.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
    },
  },
  created() {
    this.bus.$on("showEdit", (chain) => {
      this.dialog = true;
      this.chainID = chain.chainID;
      this.name = chain.name;
      this.chainClips = (chain.clips || []).map((x) => x.clip);
    });
  },
};
</script>
