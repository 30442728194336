<template>
  <v-container fluid class="scrollable">
    <div>
      <v-avatar color="grey darken-2" @click="init()">
        <v-icon size="36"> mdi-pound </v-icon>
      </v-avatar>
      <h2>Welcome to #{{ channelName }}!</h2>
      <p class="text-caption grey--text">
        This is the start of the #{{ channelName }} channel
      </p>
    </div>

    <v-divider class="chat-divider mb-3"></v-divider>

    <messages keyName="clips" :existing="messages" />

    <v-card>
      <v-card-title>
        Nutrition
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="clips"
        item-key="clip"
        :search="search"
        show-expand
        :expanded.sync="expanded"
        dense
        :items-per-page="-1"
        @click:row="toggleExpand"
      >
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <div class="d-flex align-center">
              <div><strong>Desc:</strong> {{ item.desc }}</div>
              <v-spacer></v-spacer>
              <div>
                <v-btn x-small color="red" @click="removeClip(item)"
                  ><v-icon left>mdi-trash-can</v-icon>Delete</v-btn
                >
              </div>
            </div>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import Vue from "vue";
import { mapActions, mapState, mapMutations } from "vuex";
import Messages from "components/shared/Messages.vue";

// import LottieAnimation from "lottie-web-vue";

export default {
  name: "Prune",
  components: {
    Messages,
    // LottieAnimation
  },
  data: () => ({
    bus: new Vue(),
    messages: [],
    hint: true,

    search: "",
    expanded: [],
    headers: [
      {
        text: "Clip",
        value: "name",
      },
      {
        text: "Voices",
        value: "voices",
      },
      {
        text: "Plays",
        value: "plays",
        align: "right",
      },
      {
        text: "Hidden",
        value: "hidden",
        align: "right",
      },
      {
        text: "Last Played",
        value: "lastPlayed",
      },
      {
        text: "Date Added",
        value: "createdDate",
      },
      {
        text: "Creator",
        value: "creatorDisplayName",
      },
      { text: "", value: "data-table-expand" },
    ],
    clips: [],
  }),
  computed: {
    ...mapState(["searchText", "activeGuild"]),
    ...mapState("user", ["user"]),
    channelName() {
      return (this.$route.params.voice || this.$route.name)
        .toLowerCase()
        .split(" ")
        .join("-");
    },
    foundClipsMessage() {
      return `I've found ${this.clips.length} clip${
        this.clips.length == 1 ? "" : "s"
      } for you!`;
    },
  },
  watch: {
    $route() {
      Vue.set(this.messages[0], "text", [
        `!${this.$route.name.toLowerCase()} ${this.searchText}`,
      ]);
      Vue.set(this.messages[1], "text", [this.foundClipsMessage]);
    },
    searchText(val) {
      Vue.set(this.messages[0], "edited", true);
      Vue.set(this.messages[0], "text", [
        `!${this.$route.name.toLowerCase()} ${val}`,
      ]);
      Vue.set(this.messages[1], "edited", true);
      Vue.set(this.messages[1], "text", [this.foundClipsMessage]);
    },
  },
  methods: {
    ...mapMutations(["enableSearch", "disableSearch"]),
    ...mapActions("clips", {
      getPruneList: "getPruneList",
      deleteClip: "deleteClip",
      playClip: "playClip",
    }),
    play(clip) {
      if (clip.playing) {
        return;
      }
      this.playClip(clip);
    },
    removeClip(clip) {
      this.clips.splice(this.clips.indexOf(clip), 1);
      this.deleteClip(clip);
    },
    toggleExpand(item, data) {
      data.expand(!data.isExpanded);
    },
    loadNewClips() {
      this.init();
    },
    init() {
      this.messages.splice(0);
      this.messages.push({
        avatar: this.user.avatarURL,
        username: this.user.display_name || this.user.global_name,
        time: new Date().toLocaleString(),
        text: [`!${this.$route.name.toLowerCase().split(" ").join("-")}`],
      });
      this.getPruneList().then((response) => {
        this.clips = response.data;
        this.messages.push({
          avatar: require("assets/clippy.png"),
          username: "Clippy",
          nameColor: "primary",
          bot: true,
          time: new Date().toLocaleString(),
          text: [this.foundClipsMessage],
        });
        this.hint = false;
        // this.enableSearch();
      });
    },
  },
  created() {
    this.init();
  },
  destroyed() {
    // this.disableSearch();
  },
};
</script>
